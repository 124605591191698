import Affiliate from './Affiliate'
import { BsPlayCircle, BsPauseCircle } from 'react-icons/bs'
import React, { useEffect, useMemo, useState } from "react";
import sample from "../../../images/sample.png";
import axios from "axios";
import { baseURL } from "../../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { addTemplateVarient } from "../../../actions/tempVariantAction";
import swal from "sweetalert";
import {
    addSlideDuration,
    updateStatus,
    bgVolumeChange,
    aiVolumeChange,
    removeLogoWatermark,
    updateLogoWatermark,
    changeTempVariation
} from "../../../actions/videoAction";
import HoverVideoPlayer from "react-hover-video-player";
import Alert from "../../Alert";


const Setting = ({ video, setAffilate, slideData, fonts }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const logoData = video.data.logo
    const waterMarkData = video.data.waterMark
    const selectedSlide = slideData.find(({ is_selected }) => is_selected === true)
    const selectedSlideIndex = slideData.findIndex(({ is_selected }) => is_selected === true)
    const [categoryId, setCategoryId] = useState(1)

    const [tts, setTts] = useState({
        url: "",
    })
    const [bgMusic, setBgMusic] = useState({
        src: "",
    })

    const [name, setName] = useState({
        logoName: 'Upload Your Logo',
        waterMarkName: 'Upload Your Watermark'
    });
    const logo = useMemo(() => {
        return logoData
    }, [logoData])

    const waterMark = useMemo(() => {
        return waterMarkData
    }, [waterMarkData])

    const [ttsAudio, setTtsAudio] = useState(false)
    const [bgAudio, setbgAudio] = useState(false)

    const [variants, setVariants] = useState([]);
    const [tempLoader, setTempLoader] = useState(false);

    const [loadLogo, setLoadLogo] = useState(false);
    const [loadWatermark, setLoadWatermark] = useState(false);
    const [play, setPlay] = useState(false)

    useEffect(() => {
        setTts(selectedSlide.audio)
    }, [slideData])

    useEffect(() => {
        setBgMusic(video.data.bgAudio)
    }, [video])


    useEffect(() => {
        if (tts.enable) {
            setTtsAudio(new Audio(tts.url))
        }
        if (bgMusic.enable) {
            setbgAudio(new Audio(bgMusic.src))
        }
    }, [tts.url, bgMusic.src])


    const handlePlay = () => {
        if (tts.enable || bgMusic.enable) {
            if (tts.enable) {
                ttsAudio.volume = tts.volume / 100
                ttsAudio.play()
            }
            if (bgMusic.enable) {
                bgAudio.volume = bgMusic.volume / 100
                bgAudio.play()
            }
            setPlay(true)
        }
        else {
            dispatch(setAlert("Please add Music and VoiceOver in your slide", 'warning'))
        }
    }

    const handlePause = () => {
        if (ttsAudio) {
            ttsAudio.pause()
        }
        if (bgAudio) {
            bgAudio.pause()
        }
        setPlay(false)
    }

    useEffect(() => {
        handlePause()
    }, [selectedSlideIndex])

    const addDuration = (e) => {
        let num = e.target.value
        if (num > 20) {
            dispatch(setAlert("You can't set slide duration more than 20 sec" , "warning"))
        } else {
            dispatch(addSlideDuration(num))
        }
    }

    const fileUpload = (file, type) => {
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
            if (file.size < 5000000) {
                if (type === "logo") {
                    setLoadLogo(true);
                } else {
                    setLoadWatermark(true);
                }
                let formData = new FormData();
                formData.append('file', file);
                formData.append('type', type);
                axios({
                    method: 'POST',
                    url: `${baseURL}upload-user-media`,
                    data: formData,
                    headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
                }).then(res => {
                    if (res.data.status === true) {
                        if (type === "logo") {
                            selectImage(res.data.path, type);
                            setLoadLogo(false);
                            setName({
                                ...name,
                                logoName: res.data.name
                            })
                        } else if (type === "watermark") {
                            selectImage(res.data.path, type);
                            setLoadWatermark(false);
                            setName({
                                ...name,
                                waterMarkName: res.data.name
                            })
                        }
                        dispatch(setAlert(res.data.message, 'success'))
                    } else {
                        dispatch(setAlert(res.data.message, 'danger'));
                    }
                }).catch(error => {
                    setLoadLogo(false);
                    setLoadWatermark(false);
                    console.log(error)
                })
            } else {
                swal("Oops!", "Max allowed size 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    const logoWaterPos = (position, type) => {
        let fileType = 'UPDATE_LOGO_POSITION';
        if (type === 'watermark') {
            fileType = 'UPDATE_WATERMARK_POSITION';
        }
        dispatch(updateLogoWatermark(fileType, position));
    }

    const onLogoUpload = (e) => {
        fileUpload(e.target.files[0], 'logo');
    }

    const onWatermarkUpload = (e) => {
        fileUpload(e.target.files[0], 'watermark');
    }

    const selectImage = (url, type) => {

        let uploadType = 'ADD_LOGO';
        if (type === "watermark") {
            uploadType = 'ADD_WATERMARK';
        }

        dispatch(updateLogoWatermark(uploadType, url));
    }

    const handleLogoWaterMark = (e, type) => {

        if (type === 'logo') {
            dispatch(updateStatus(e.target.checked, 'logo'))
            if (e.target.checked === false) {
                setName({
                    ...name,
                    logoName: 'Upload Your Logo'
                })
                dispatch(removeLogoWatermark('REMOVE_LOGO_LAYER'))
            }
        }
        if (type === 'watermark') {
            dispatch(updateStatus(e.target.checked, 'waterMark'))
            if (e.target.checked === false) {
                dispatch(removeLogoWatermark('REMOVE_WATERMARK_LAYER'))
                setName({
                    ...name,
                    waterMarkName: 'Upload Your Watermark'
                })
            }
        }
    }

    useEffect(() => {
        if (selectedSlide !== undefined)
            setCategoryId(selectedSlide.categoryID)
    }, [selectedSlide])

    useEffect(() => {
        setTempLoader(true)
        axios({
            method: "POST",
            url: `${baseURL}fetch-template-list`,
            data: JSON.stringify({ categoryId: selectedSlide.categoryID }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setVariants(res.data.data);
                dispatch(addTemplateVarient(res.data.data))
            }
            setTempLoader(false)
        }).catch(err => {
            console.log(err)
            setTempLoader(false)
        })

    }, [categoryId])

    const handleTemplateChange = (templateData) => {
        dispatch(changeTempVariation(templateData, selectedSlideIndex))
    }

    const handleVolumeChange = (e, type) => {
        handlePause()
        switch (type) {
            case 'music':
                const bgAction = 'CHANGE_BG_VOLUME'
                dispatch(bgVolumeChange(bgAction, e.target.value))
                break;

            case 'voiceover':
                const voiceAction = 'CHANGE_VOICE_VOLUME'
                dispatch(aiVolumeChange(voiceAction, e.target.value, selectedSlideIndex))
                break;

            default:
                break;
        }

    }

    return (
        <>
            <Alert />
            <div className="tabInner">
                <div className="media-block">
                    <div className="add-block-single">
                        <div className="add-block-main">
                            <div className="add-block-title  mb-4">
                                <h2>Add Logo</h2>
                                <div className="switch-single">
                                    <label className="switch" htmlFor="logo">
                                        <input
                                            type="checkbox"
                                            value={logo.enable}
                                            id="logo"
                                            onChange={(e) => handleLogoWaterMark(e, logo.type)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {logo.enable ?
                                <>
                                    <div className="fileUpload" style={logo.src ? { color: '#ffa200' } : {}}>
                                        <i className="fa fa-cloud-upload" aria-hidden="true"></i> {name.logoName.length > 20 ? `${name.logoName.slice(0, 20)}...` : name.logoName}
                                        <input
                                            type="file"
                                            className='cursor-pointer'
                                            onChange={(e) => onLogoUpload(e)}
                                        />
                                    </div>
                                    <div className="itemDisp">
                                        <div className="itemDisp-left text-center">
                                            <ul>
                                                <li style={{ position: 'relative' }}>
                                                    {
                                                        loadLogo ? <i className="fa fa-spinner fa-spin loader-center" />
                                                            : <img
                                                                src={logo.src ?
                                                                    logo.src : sample}
                                                                alt=""
                                                                className="uploadImg"
                                                            />
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="itemDisp-right">

                                            <select onChange={(e) => logoWaterPos(e.target.value, 'logo')} className='cursor-pointer'>
                                                <option value={'top-left'}>Top Left</option>
                                                <option value={'top-center'}>Top Mid</option>
                                                <option value={'top-right'}>Top Right</option>
                                                <option value={'bottom-left'}>Bottom Left</option>
                                                <option value={'bottom-center'}>Bottom Mid</option>
                                                <option value={'bottom-right'}>Bottom Right</option>
                                            </select>
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>


                    <div className="add-block-single">
                        <div className="add-block-main">
                            <div className="add-block-title  mb-4">
                                <h2>Add Watermark</h2>
                                <div className="switch-single">
                                    <label className="switch" htmlFor="watermark">
                                        <input
                                            type="checkbox"
                                            id="watermark"
                                            value={waterMark.enable}
                                            onChange={(e) => handleLogoWaterMark(e, waterMark.type)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            {waterMark.enable ?
                                <>
                                    <div className="fileUpload" style={waterMark.src ? { color: '#ffa200' } : {}}>
                                        <i className="fa fa-cloud-upload" aria-hidden="true"></i> {name.waterMarkName.length > 23 ? `${name.waterMarkName.slice(0, 23)}...` : name.waterMarkName}
                                        <input
                                            type="file"
                                            id="inputGroupFile01"
                                            className='cursor-pointer'
                                            onChange={(e) => onWatermarkUpload(e)}
                                        />
                                    </div>
                                    <div className="itemDisp">
                                        <div className="itemDisp-left text-center">
                                            <ul>
                                                <li style={{ position: 'relative' }}>
                                                    {
                                                        loadWatermark ?
                                                            <i className="fa fa-spinner fa-spin loader-center" />
                                                            : <img
                                                                src={waterMark.src ? waterMark.src : sample}
                                                                alt=""
                                                                className="uploadImg"
                                                            />
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="itemDisp-right">
                                            <select onChange={(e) => logoWaterPos(e.target.value, 'watermark')} className='cursor-pointer'>
                                                <option value={'top-left'}>Top Left</option>
                                                <option value={'top-center'}>Top Mid</option>
                                                <option value={'top-right'}>Top Right</option>
                                                <option value={'bottom-left'}>Bottom Left</option>
                                                <option value={'bottom-center'}>Bottom Mid</option>
                                                <option value={'bottom-right'}>Bottom Right</option>
                                            </select>
                                        </div>
                                    </div>
                                </> : ''}
                        </div>
                    </div>


                    <div className="add-block-single">
                        <div className="add-block-main">
                            <div className="add-block-title mb-4">
                                <h2>Duration</h2>
                            </div>
                            <input
                                className="inPut"
                                type="number"
                                name="duration"
                                min="1"
                                // max={10}
                                value={selectedSlide.duration}
                                onChange={(e) => addDuration(e)}
                            />
                        </div>
                    </div>

                    <div className="add-block-single">
                        <div className="add-block-main">
                            <div className="add-block-title mb-4">
                                <h2>Volume Control</h2>
                                <span>
                                    {play ? <BsPauseCircle
                                        fontSize={20}
                                        onClick={() => handlePause()}
                                        className="cursor-pointer"
                                    /> :
                                        <BsPlayCircle
                                            fontSize={20}
                                            onClick={() => handlePlay()}
                                            className="cursor-pointer"


                                        />}
                                </span>

                            </div>
                            <div>
                                <h2 className="pb-2">Voiceover <span style={{ float: 'right' }}>{`${selectedSlide.audio.volume}%`}</span></h2>
                                <input
                                    type="range"
                                    min={1}
                                    className="slidern cursor-pointer"
                                    name="voiceOver"
                                    id="myRange"
                                    value={selectedSlide.audio.volume}
                                    onChange={e => handleVolumeChange(e, 'voiceover')}
                                />

                                <h2 className="pb-2 mt-4">Music  <span style={{ float: 'right' }}>{`${video.data.bgAudio.volume}%`}</span></h2>
                                <input
                                    type="range"
                                    min={1}
                                    className="slidern cursor-pointer"
                                    id="myRange"
                                    name="music"
                                    value={video.data.bgAudio.volume}
                                    onChange={e => handleVolumeChange(e, 'music')}
                                />
                            </div>
                        </div>
                    </div>
                    {variants !== "" ?
                        <div className="add-block-single">
                            <div className="add-block-main">
                                <div className="add-block-title mb-4">

                                    <h2>Templates</h2>
                                </div>
                                <div className="mediaList">
                                    <ul>
                                        {tempLoader ? <div className="set-Loader"><i className="fa fa-spinner fa-spin m-5 " /> </div> :

                                            variants !== "" ?
                                                variants.map((item) => {
                                                    return (
                                                        <li
                                                            key={item.id}
                                                            style={{ position: 'relative', cursor: 'pointer' }}
                                                            onClick={() => handleTemplateChange(item)}
                                                        >
                                                            {parseInt(selectedSlide.templateNumber) === parseInt(item.template_number) ?
                                                                <div className="slide-active">
                                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                                </div>
                                                                : ''
                                                            }
                                                            <HoverVideoPlayer
                                                                videoSrc={item.prview_video_url}
                                                                pausedOverlay={
                                                                    <img src={item.preview_image_url} alt={item?.template_name} className="mediaImg" />
                                                                }
                                                                loadingOverlay={
                                                                    <div className="loader-center-temp1"><i className="fa fa-spinner fa-spin mr-2" /></div>
                                                                }
                                                            />
                                                        </li>
                                                    )
                                                }) : ''
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div> : ''}
                    <Affiliate
                        setAffilate={setAffilate}
                        fonts={fonts}
                    />


                </div>
            </div>
        </>
    );
}

export default Setting;
