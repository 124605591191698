import React from 'react';

const TemplateLogo = (props) => {
    return (
        <>
            {
                props.logo.src !== "" ?
                    <div className={`logo-${props.logo.postion} logo-style`}><img src={props.logo.src} alt="logo" /></div>
                    : ''
            }
        </>
    );
}

export default TemplateLogo;
