import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { baseURL, slideObject } from '../../global/global';
import { onSelectStoryTile } from '../../actions/videoAction'

const StoryEditor = ({ slideData }) => {

    const dispatch = useDispatch()

    const selectedSlide = slideData.find(({ is_selected }) => is_selected === true)
    const selectedSlideIndex = slideData.findIndex(({ is_selected }) => is_selected === true)

    const [scriptText, setScriptText] = useState([])


    const onSelectTile = (scriptIndex, text) => {
        const tts = {
            url: '',
            tts: {
                text: text,
                languageId: "",
                voiceId: "",
                translateText: "",
                engine: ""
            }
        }
        dispatch(onSelectStoryTile(selectedSlideIndex, scriptIndex, tts))
    }

    useEffect(() => {
        if (selectedSlide) {
            setScriptText(selectedSlide.scriptText)
        }
    }, [selectedSlide])

    return (
        <div className="tabInner">
            {scriptText.length > 0 ?
                <>
                    <h2 style={{ fontSize: '18px' }}>Select a script variation</h2>
                    <div className="story-block">
                        <ul>
                            {scriptText.map((curElem, index) => {
                                return (
                                    <li className={curElem.is_seclected ? `active` : ''} key={index}>
                                        <div className="story-block-single cursor-pointer" onClick={() => onSelectTile(index, curElem.text)} >
                                            <p>{curElem.text}</p>
                                        </div>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                </>
                : ''}



            {/* {selectedSlide !== false ?
                selectedSlide.scriptText.length > 0 ? <>
                    <h2 style={{ fontSize: '18px' }}>Select a script variation</h2>
                    <div className="story-block">
                        <ul>
                            {selectedSlide.scriptText.map((curElem, index) => {
                                return (
                                    <li className={curElem.is_seclected ? `active` : ''} key={index}>
                                        <div className="story-block-single cursor-pointer" onClick={() => onSelectTile(index)} >
                                            <p>{curElem.text}</p>
                                        </div>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                </>
                    : '' : ''} */}
        </div>
    );
}

export default StoryEditor;
