import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import UpgradesMenu from "./UpgradesMenu";
import UpgradesContent from "./UpgradesContent";
import Title from "../Title";

const Upgrades = () => {
    const [w1, setW1] = useState(false)
    const [w2, setW2] = useState(false)
    const [w3, setW3] = useState(false)
    const [w4, setW4] = useState(false)
  
    return (
        <>
            <Title title="Upgrades" />
            <Navbar />

            <section className="upgrads-sec">
                <div className="container">
                    <div className="row">
                        <UpgradesMenu
                            w1={w1}
                            w2={w2}
                            w3={w3}
                            w4={w4}
                            setW1={setW1}
                            setW2={setW2}
                            setW3={setW3}
                            setW4={setW4}
                        />
                        <UpgradesContent
                            w1={w1}
                            w2={w2}
                            w3={w3}
                            w4={w4}
                        />
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Upgrades;