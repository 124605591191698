import React, { useState } from 'react';
import { List1style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <List1style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}

                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                // type='image'
                />
                <div className="template-preview flex-column">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="head-box text-center">
                                    <h1>Feature list</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="Feature-list text-center ">
                                    {props.templateText.map((curElem) => {
                                        return (
                                            <div className="text-content" key={curElem.id}>
                                                <div className={`text-wrappers-${curElem.id} `}>
                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.text.is_selected}
                                                        layerId={curElem.id}
                                                        text={curElem.text.text}
                                                        style={curElem.text.style}
                                                        setTextLength={props.setTextLength}
                                                        setMaxLimit={props.setMaxLimit}
                                                        templateNumber={props.templateNumber}
                                                        blockType={
                                                            {
                                                                tag: 'p',
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </List1style >

    );

};
export default Template;