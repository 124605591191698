import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {useDispatch, useSelector} from "react-redux";
import {gdprAction} from "../../actions/authAction";

const PrivacyGdpr = () => {

    const dispatch = useDispatch();

    const [button, setButton] = useState('Delete Data');
    const [msgData ,setMsgData] = useState('You will not be able to recover this file');
    const [showDelete, setShowDelete] = useState(false);
    const [type, setType] = useState('delete');
    const rebrand = useSelector(state=>state.rebrand.data)

    const modalData = [
        {
            message : 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
        },
        {
            message : 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
        },
        {
            message : `${rebrand ? rebrand.name : 'ReviewReel'}  stores your private information. If you wish to download these data, you can do that by clicking Download My Data button.`
        }
    ]

    const onConfirm = () => {
        setButton('Please Wait...');

        if(type === 'delete'){
            dispatch(gdprAction('','delete-account',setShowDelete))
        }
        else if(type === 'stop'){
            dispatch(gdprAction('','disable-account',setShowDelete));
        }
        else if(type === 'download'){
            dispatch(gdprAction('','download-user-data',setShowDelete));
        }

    }

    const onButtonClick = (e) =>{
        setShowDelete(true);

        if(e === 'delete'){
            setMsgData(modalData[0].message);
            setButton('Delete Data');
            setType('delete');
        }
        else if(e === 'stop'){
            setMsgData(modalData[1].message);
            setButton('Stop Processing');
            setType('stop');
        }
        else if(e === 'download'){
            setMsgData(modalData[2].message);
            setButton('Download');
            setType('download');
        }
    }

    const onCancel = (e) => {
        setShowDelete(false);
    }

    return (
        <>
            <div className="manage-data-sec  mt-5">
                <h5 className="text-white">Manage Your Account Data</h5>
                <div className="manage-data-box mt-3 text-white ">
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <p>{rebrand? rebrand.name : 'ReviewReel' } stores your private information like your name, email. It also stores your
                                uploaded graphics such as images, videos and your music files along with TTS ( Text to
                                speech). If you wish to download these data, you can do that "by clicking" download your
                                data.</p>
                            <div className="manage-update-btn mb-5">
                                <button type="button" 
                                        style={{backgroundColor: "rgb(0, 123, 255)"}}
                                        onClick={(e)=>onButtonClick('download')}
                                >DOWNLOAD MY DATA
                                </button>
                            </div>
                        </div>
                    </div>

                    <hr className="m-0" style={{color:"#707070", overflow:"scroll"}} />


                        <div className="row mt-3">
                            <div className="col-md-12">
                                <p>If you wish to stop your private data from getting processed on {rebrand ? rebrand.name : 'ReviewReel' } you can
                                    click the button to do so. Please note that doing this will disable your account and
                                    you will not be able to use {rebrand ? rebrand.name : 'ReviewReel' } further. In order to reactivate your account,
                                    you would have to contact our support team at <a
                                        href="https://support.vineasx.com/">support@vineasx.com</a>. <span>Please Proceed With Caution.</span>
                                </p>
                                <div className="manage-update-btn mb-5">
                                    <button type="button" 
                                            style={{backgroundColor:"rgba(255, 116, 6, 0.86)"}}
                                            onClick={(e)=>onButtonClick('stop')}
                                    >
                                        STOP PROCESSING MY DATA
                                    </button>
                                </div>
                            </div>
                        </div>

                        <hr className="m-0" style={{color:"#707070", overflow:"scroll"}} />


                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <p>If you wish to delete all your private data, you can do so by clicking this
                                        button. Please note that this will permanently delete all the data of yours from
                                        our server. This step is not reversible and you cannot use {rebrand? rebrand.name : 'ReviewReel' } further.
                                        You cannot undo this step from support also. So if you wish to re-use {rebrand? rebrand.name : 'ReviewReel' }
                                        again, then you will have to start all over again from scratch. <span>Please Proceed With Caution.</span>
                                    </p>
                                    <div className="manage-update-btn mb-3">
                                        <button type="button" 
                                                style={{backgroundColor: "rgba(255, 6, 6, 0.86)"}}
                                                onClick={(e)=>onButtonClick('delete')}
                                        >DELETE MY DATA
                                        </button>
                                    </div>
                                </div>
                            </div>


                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                {msgData}
            </SweetAlert>
        </>
)
}

export default PrivacyGdpr;