import styled from "styled-components";
export const Bonuses4style = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
    .template-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        text-align:center;
    }

    .template-wrapper img{
        max-height:100%;
    }

    .template-preview {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
    }

    .head-box{
        border: 2px solid #000;
        width: 186px;
        margin: 2px auto;
        padding: 1px 0 1px 12px;
        background:#fff;
        box-sizing:border-box;
    }
    .head-box h1{
        color:#000;
        font-size:22px;
        letter-spacing:20px;
        margin-bottom:0;
        font-weight:800;
        font-family: 'Montserrat', sans-serif;
    }

    .bonus-wrapper-1{
        position: absolute;
        top: 13px;
        left: 11px;
    }

    .text-wrappers{
        margin-top:5px;
        margin-top: 5px;
        padding: 0 1px;
    }
    .text-wrappers p{
        margin-bottom:0px;
    }

    .price-value{
        position: absolute;
        top: 42px;
        left: 46px;
        z-index: 1;
    }
    
     .bonus-wrapper-2{
        position: absolute;
        top:92px;
        left: 64px;
    }
    
     .bonus-wrapper-3{
        position: absolute;
        top: 171px;
        left: 120px;
    }
    
     .bonus-wrapper-4{
        position: absolute;
        top: 250px;
        left: 176px;
    }

   .bonus-shape{
        width: 354px;
        height: 56px;
        border: 3px solid #000;
        background:#fff;
        box-sizing: border-box;
    }
   .bonus-img {
        width: 120px;
        position: absolute;
        right: -104px;
        top: -29px;
    }
@media (max-width: 1199.98px) { 
    
 
    .bonus-shape {
        width: 307px;
        height: 47px;
        border: 3px solid #000;
        background: #fff;
        box-sizing: border-box;
    }
    .bonus-wrapper-1 {
        position: absolute;
        top: 11px;
        left: 11px;
    }
     .bonus-wrapper-2 {
        position: absolute;
        top: 78px;
        left: 64px
    
    }
    
    .bonus-wrapper-3 {
        position: absolute;
        top: 145px;
        left: 120px
    
    }
    .bonus-wrapper-4 {
        position: absolute;
        top: 212px;
        left: 144px;
    }
    
     .bonus-img {
        width: 102px;
        position: absolute;
        right: -85px;
        top: -24px;
    }
     .price-value {
        position: absolute;
        top: 32px;
        left: 36px;
        z-index: 1;
    }
    
}

`;