import styled from "styled-components";
export const List3style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }
 
  .head-box h1{
    text-transform: uppercase;
    font-size: 35px;
    font-weight: bold;
  }

.myStyle{
    color: ' #fff',
    fontSize: '47px',
    fontFamily: '',
    textAlign: ' center',
    textTransform: 'uppercase',
}

svg {
    width:65%;
}
.feature-wrapper{
    position: absolute;
    top: -6px;
    left: 1px;
   }

.feature_list-1 {
    width:100%;
    height:auto;

}

.list-card {
    position: relative;
}

.list-card-2 {
    position: relative;
}

.list-card-3 {
    position: relative;
}

.list-1 {
    width: 451px;
    left: -55px;
    filter: drop-shadow(5px 3px 2px rgba(50,50,0,0.5)) !important;
    position: absolute;
    top: 0;
}

.line-1 {
    width: 12px;
    height: 64px;
    display: inline-block;
    background: #FF2828;
    filter: drop-shadow(3px 4px 1px rgba(50,50,0,0.5)) !important;
    position: absolute;
    left: 5px;
}

.feature_list-2 {
    width: 100%;
    height: auto;
    padding-top: 50px;
    display: block;
    position: absolute;
    top: 115px;
}
    
.feature-wrapper-2{
    position: absolute;
    top: 70px;
    left: -9px;
}

.list-2 {
    width: 451px;
    position: absolute;
    top: 0;
    left: -55px;
    filter: drop-shadow(5px 3px 2px rgba(50, 50, 0, 0.5)) !important;

}

.line-2 {
    width: 12px;
    height: 64px;
    display: inline-block;
    background: #FF2828;
    filter: drop-shadow(3px 4px 1px rgba(50,50,0,0.5)) !important;
    position: absolute;
    left: 5px;
}


.text-wrappers {
    position: absolute;
    top: 1px;
    left: 83px;
    width: 271px;
    height: 100%;
    overflow: hidden;
}

.text-wrappers P {

    padding-top: 2px;
}


@media (max-width: 1199.98px) { 
    .feature-wrapper{
        position: absolute;
        left: -3px;
        top:-5px;
     }
     svg {
         width: 50%;
     }

     .list-card {
        position: relative;
        left: -60px;
    }
    .list-1 {
        width: 495px;
        left: -34px;
        filter: drop-shadow(5px 3px 2px rgba(50,50,0,0.5)) !important;
        position: absolute;
        top: 0;
    }
     .line-1 {
         width:9px;       
         left: 75px;
         height:53px;
     }
     
     .list-2 {
        width: 495px;
        position: absolute;
        top: 0;
        left: -34px;
    }


     .line-2 {
        width:9px;
        left: 75px;
        height:53px;
     }
      
     .feature_list-2 {
         width: 100%;
         height: auto;
         padding-top: 50px;
         display: block;
         position: absolute;
         top: 87px;
     }
     
     .text-wrappers {
      position: absolute;
      top: 5px;
      left: 127px;
      width: 214px;
      height: 100%;
      overflow: hidden;
     }
     
     .text-wrappers P {
         padding-top:0;
     }
     
     .feature-wrapper-2 {
       position: absolute;
       top: 50px;
       left: -13px;
     }
     
      

}
 


`;