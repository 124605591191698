import styled from "styled-components";
export const Conclusion4style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }
  .tag-line{
    cursor: not-allowed;
  }
  .tag-line img{
    width:295px;
  }

  .head-name{
    position: absolute;
    top: 11px;
    left: 36%;
  }
  
  .head-name span{
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
  }
  
  .text-wrappers{
    padding-top:25px;
  }
  .text-wrappers p,.text-wrappers-2 p {
    padding:8px
  }
  .text-wrappers-2{
    padding-top:25px;
  }

@media (max-width: 1199.98px) { 
  .text-wrappers {
    padding-top: 0;
    height:auto;
   }
  h1 {
    font-size: 35px;
  }
  .text-wrappers-2 {
    padding-top:16px;
    height:auto;
    overflow: hidden;
  }
  .text-wrappers p,.text-wrappers-2 p {
    width: 100%;
    height: 100%;
    overflow:hidden;
  }
   
}


`;