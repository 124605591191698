import styled from "styled-components";

export const Testimonial5style = styled.div`

html {
	font-size: 62,5%; 
}

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }
  
  .template-wrapper img{
    max-height:100%;
  }
  
  .template-preview {
    position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }
  .card-1{
    border-radius: 12px;
    width: 9.2rem;
    height: 21.4rem;
    overflow: hidden;
    border: none;
    position: absolute;
    top: 7px;
    left: 3px;
    overflow: visible;
    background:none;
  }

  .image-wrapper{
    position: relative;
    top: -23px;
    left: -10px;
  }
  .card-img-background {
    position: relative;
    width: 194px;
    
  }

  .text-head-wrapper{
    position: relative;
    top:164px;
  }
 

.card-img-box-1 img {
    width: 150px;
    height: 130px;
   
}

.card-img-box-2 img {
    width: 150px;
    height: 130px;
   

}

.card-img-box-3 img {
    width: 150px;
    height: 130px;
   
}

.card-img-box-4 img {
    width: 150px;
    height: 130px;
}

.card-img-box-1 {
    position: absolute;
    left: 10px;
    top: 34px;
    width: 130px;
    height: 130px;
    text-align: center;
    box-sizing: border-box;
    transform: rotate(11deg);
}

.card-img-box-2 {
    position: absolute;
    left: 14px;
    top: 34px;
    width: 130px;
    height: 125px;
    text-align: center;
    box-sizing: border-box;
    transform: rotate(354deg);
}

.card-img-box-3 {
    position: absolute;
    left: 19px;
    top: 34px;
    width: 130px;
    height: 125px;
    text-align: center;
    box-sizing: border-box;
    transform: rotate(11deg);
}

.card-img-box-4 {
    position: absolute;
    left: 24px;
    top: 34px;
    width: 130px;
    height: 125px;
    text-align: center;
    box-sizing: border-box;
    transform: rotate(354deg);
}
  .card-body{
    padding:5px;
  }
  .quotation{
    position: absolute;
    top: 60px;
    left: 14px;
  }
  .quotation img{
     width:21px;
  }

  .texts-wrapper{
    position: relative;
    top: 99px;
  }
@media (max-width: 1199.98px) { 

 
 }

`;