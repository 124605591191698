import styled from "styled-components";
import bg from '../../../../../images/descripation-bg5.png';

export const Description5style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview-background {
      background:url(${bg});
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      width: 94%;
      height: 360px;
      top: 0;
      right: 0;
   }
    
  }
  .template-preview {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
    
  }
  .video-player{
    width:220px!important;
    max-height:100%;
  }
  
  .video-player video{
    object-fit:unset;
  }

.video-player{
    width:220px!important;
    max-height:100%!important;
} 

.product-wrappers{
  height: 240px;
  position: absolute;
  top: 48px;
  right: 28px;
}  

.product-wrappers-2{
    height: 175px;
    position: absolute;
    top: 86px;
    right: 28px;
}  

.product-wrappers img{
  max-height:100%
}

.head-box{
  position: absolute;
    top: 90px;
    left: 12px;
    width: 302px;
    height: auto;
}
.head-box h1{
  margin-bottom:0;
  width:100%;
  height:auto;
  overflow:hidden;
}
.text-wrappers{
  width: 302px;
  position: absolute;
  top: 170px;
  left: 12px;
}
.text-wrappers p{
    margin-bottom:0;
    width:100%;
    height:auto;
    overflow:hidden;
}

@media (max-width: 1199.98px) { 
  .head-box{
    position: absolute;
    top: 45px;
    left: 10px;
    width: 90%;
    height: auto;
  }
  .head-box h1{
    margin-bottom:0;
    width:100%;
    height:auto;
  }
  .text-wrappers{
    position: absolute;
    top: 135px;
    left: 10px;
    width: 90%;
    height: auto;
  }

  .product-wrappers{
    height: 203px;
    position: absolute;
    top: 54px;
    left: 14px;
  }  

  .product-wrappers-2 {
    height: 146px;
    position: absolute;
    top: 86px;
    right: 28px;
  }
  
}

`;