import React, { useState } from 'react';
import { Conclusion3style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Conclusion3style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview ">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-2 ">
                                <div className="product-intro">
                                    <div className="head-box">
                                        <h1 className="m-0">C</h1>
                                        <h1 className="m-0">o</h1>
                                        <h1 className="m-0">n</h1>
                                        <h1 className="m-0">c</h1>
                                        <h1 className="m-0">l</h1>
                                        <h1 className="m-0">u</h1>
                                        <h1 className="m-0">s</h1>
                                        <h1 className="m-0">i</h1>
                                        <h1 className="m-0">o</h1>
                                        <h1 className="m-0">n</h1>

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="background-color">
                                    <div className="text-wrappers ">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].text.is_selected}
                                            layerId={props.templateText[0].id}
                                            text={props.templateText[0].text.text}
                                            style={props.templateText[0].text.style}
                                            templateNumber={props.templateNumber}
                                            setTextLength={props.setTextLength}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'p',
                                                }

                                            }
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="text-wrappers-2 ">
                                                <TextEditor
                                                    layer={layer}
                                                    setLayer={setLayer}
                                                    layerStatus={props.templateText[1].is_selected}
                                                    textStatus={props.templateText[1].text.is_selected}
                                                    layerId={props.templateText[1].id}
                                                    text={props.templateText[1].text.text}
                                                    templateNumber={props.templateNumber}
                                                    style={props.templateText[1].text.style}
                                                    setTextLength={props.setTextLength}
                                                    setMaxLimit={props.setMaxLimit}
                                                    blockType={
                                                        {
                                                            tag: 'p',
                                                        }

                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Conclusion3style >
    );

};
export default Template;