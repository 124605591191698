import styled from "styled-components";
export const Dedicated5style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }
  

.product-wrappers {
  height: 156px;
  position: absolute;
  top: 86px;
  left: 69px;
  z-index: 1;
}
.product-wrappers-2 {
  height: 136px;
  position: absolute;
  top: 86px;
  left: 69px;
  z-index: 1;
}

 .product-wrappers img{
   max-height:100%
 }

.head-box {
  width: 100%;
  position: absolute;
  top: 53px;
  left: 20px;
}

.head-box h1 {
    font-size: 30px;
}

.template-preview-bg {
  background:#1A1E25;
    width: 584px;
    height: 310px;
    position: absolute;
    top: 25px;
    left: 25px;
}

.shape{
  width: 250px;
    height: 310px;
    position: absolute;
    top: 25px;
    right: 85px;
}

svg{
    width: 320px;
    height:310px;
   
}

.text-wrappers {
  width:100%;
  position: absolute;
  top: 138px;
  left:20px;
}

.video-player{
  width:180px!important;
  max-height:100%;
}



@media (max-width: 1199.98px) { 
  
    .text-wrappers {
      top: 135px;
    }
    .product-wrappers {
        height: 190px;
    }

  .product-wrappers {
    height: 135px;
    position: absolute;
    top: 61px;
    left: 53px;
    z-index: 1;
  }

  .product-wrappers-2 {
    height: 114px;
    position: absolute;
    top: 86px;
    left: 53px;
    z-index: 1;
  }
 

  .template-preview-bg {
    background: red;
    width: 525px;
    height: 288px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .shape{
   width: 250px;
    height: 288px;
    position: absolute;
    top: 10px;
    right: 73px;
  }
  svg {
    width: 320px;
    height: 288px;
  }
 
    
}


`;