import React, { useState, useEffect } from "react";
import videop from "../../images/videop.png";
import group24 from "../../images/Group 24.png";
import axios from "axios";
import fevicon_img from '../../images/fvb.png'
import { baseURL } from "../../global/global";
import { useDispatch } from "react-redux";
import { addVideoData, handleDragStatus } from "../../actions/videoAction";
import Alert from "../Alert";
import InfiniteScroll from "react-infinite-scroll-component";
import Render from "./Render";



const VideoCard = (props) => {
    const [loader, setLoader] = useState(false)
    const [totalRecord, setTotalRecord] = useState(0)
    const [project, setProject] = useState([])

    useEffect(() => {
        fetchProject(totalRecord)
    }, []);

    const fetchProject = (total) => {
        const data = { "totalShowRecord": total }
        setLoader(true)
        axios({
            method: 'POST',
            url: `${baseURL}project-fetchs`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem('state')).auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                handleResponse(res.data, total)
            }
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
    }

    const handleResponse = (res, total) => {
        if (props.topProject) {
            let arr = res.data
            arr = arr.slice(0, props.topProject)
            setProject(arr)
        }
        else {
            if (total === 0) {
                setProject(res.data)
            }
            else {
                setProject(project.concat(res.data))
            }
            if (res.total_show_record !== undefined) {
                props.setTotalProject(res.total_show_record)
                setTotalRecord(res.total_show_record)
            }
        }
    }
    return (
        <div className="row mt-5 altRow">
            <Alert />
            <InfiniteScroll
                dataLength={project.length}
                hasMore={true}
                next={() => fetchProject(totalRecord)}
            >
                {
                    project.length > 0 ?
                        project.filter((curElem) => {
                            if (!props.topProject && props.q) {
                                return curElem.campaingName?.toLowerCase().includes(props.q?.toLowerCase())
                            }
                            else {
                                return curElem
                            }
                        }).length > 0 ?
                            project.filter((curElem) => {
                                if (!props.topProject && props.q) {
                                    return curElem.campaingName?.toLowerCase().includes(props.q?.toLowerCase())
                                }
                                else {
                                    return curElem
                                }
                            }).map((curElem, index) => {
                                return (
                                    <Render
                                        curElem={curElem}
                                        key={index}
                                        fetchProject={fetchProject}
                                        setProject={setProject}
                                    />
                                )
                            }) :
                            !loader ? <div className="no-campaign-card col-md-12">
                                <div className="no-campaign-card-box text-center text-muted ">
                                    <h4>Data Not Found</h4>
                                </div>
                            </div> : ''
                        : !loader ? <div className="no-campaign-card col-md-12">
                            <div className="no-campaign-card-box text-center text-muted ">
                                <h4>You do not have any project created in your account</h4>
                            </div>
                        </div> : ''
                }
                {loader ?
                    <div className="col-md-12">
                        <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                    </div> : ''
                }

            </InfiniteScroll >


        </div >

    )
}

export default VideoCard;