import React, { useState, useEffect } from "react";
import { baseURL } from "../../global/global";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import VideoCard from "./VideoCard";
import Footer from "../Footer";
import Title from "../Title";
import { Link } from "react-router-dom";
import ReelMarge from "./ReelMerge";
import MergeCard from "./MergeCard";
import { useSelector } from "react-redux";
import axios from "axios";

const Projects = () => {
    const [totalProject, setTotalProject] = useState(0);
    const [q, setQ] = useState('')
    const [videoType, setVideoType] = useState("video")
    const [mergeVideo, setMergeVideo] = useState([])
    const [loader, setLoader] = useState(false)
    const auth = useSelector(state => state.auth)
    const [searchKeyword, setSearchKeyword] = useState("");

    const handleChange = (val) => {
        if (val === "mergeVideo") {
            fetchMerge()
            setTotalProject(0)
        } else {
            setMergeVideo([])
        }
        setVideoType(val)
    }

    const handleChangeValue = (e) => {
        setQ(e.target.value);
    }
    const fetchMerge = () => {
        setLoader(true)
        axios({
            method: "POST",
            url: `${baseURL}fetch-merge-campaigns`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setTotalProject(res.data.data.data.length)
                setMergeVideo(res.data.data.data.reverse());
            }
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            console.log(err)

        })
    }

    return (
        <>
            <Title title="Projects" />
            <Navbar />

            <section className="project-banner">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-5  d-flex justify-content-lg-start justify-content-center">
                            <div className="project-header-name d-flex  text-white">
                                <div className="project-item d-flex align-items-center">
                                    <h4 className="m-0">ALL PROJECTS</h4>
                                </div>
                                <div className="project-conut d-flex align-items-center">
                                    <h6 className="m-0">{totalProject} Project</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7  d-flex justify-content-lg-end justify-content-center">
                            <div className="project-button d-flex text-white align-items-center ">
                                <div>
                                    <select
                                        onChange={(e) => handleChange(e.target.value)}
                                        className="merge-btn"
                                        value={videoType}
                                    >
                                        <option value={"video"}>Video</option>
                                        <option value={"mergeVideo"}>Merge Video</option>
                                    </select>
                                </div>
                                <div className="project-input-box">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fas fa-search"></i></span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Projects"
                                            name="search"
                                            value={q}
                                            onChange={(e) => handleChangeValue(e)}
                                        />
                                    </div>
                                </div>
                                <Link to={'/create-video'} className="project-create-btn">
                                    <button type="button" className="btn-change6"><span className="mr-2">
                                        <i className="fas fa-plus-circle"></i></span> Create New Project
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <span className="bottom-line"></span>
                    </div>
                    {videoType === "video" ?
                        <VideoCard
                            setTotalProject={setTotalProject}
                            q={q}
                        /> :

                        <div className=" row mt-2">
                            {mergeVideo.length > 0 ?
                                mergeVideo.filter((curElem) => {
                                    if (q) {
                                        return curElem.name.toLowerCase().includes(q.toLowerCase())
                                    }
                                    else {
                                        return curElem
                                    }
                                }).length > 0 ?
                                    mergeVideo.filter((curElem) => {
                                        if (q) {
                                            return curElem.name.toLowerCase().includes(q.toLowerCase())
                                        }
                                        else {
                                            return curElem
                                        }
                                    }).map((curElem, index) => {
                                        return (
                                            <MergeCard
                                                fetchMerge={fetchMerge}
                                                item={curElem}
                                                key={index}
                                            />
                                        )

                                    }) : <>
                                        <div className="no-campaign-card col-md-12">
                                            <div className="no-campaign-card-box text-center text-muted mt-3">
                                                <h4>Data Not Found</h4>
                                            </div>
                                        </div>
                                    </>
                                : !loader ? <div className="no-campaign-card col-md-12">
                                    <div className="no-campaign-card-box text-center text-muted mt-3">
                                        <h4>You do not have any "Merge Project" in your account</h4>
                                    </div>
                                </div> : ''
                            }
                            {loader ?
                                <div className="col-md-12">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div> : ''
                            }
                        </div>


                    }

                </div>
            </section>

            <Footer />
        </>
    )
}

export default Projects;