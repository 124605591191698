import { combineReducers } from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import supportReducer from "./supportReducer";
import rebrandReducer from "./rebrandReducer";
import socialReducer from "./socialReducer";
import videoReducer from "./videoReducer";
import variantReducer from './variantReducer'
import { WhiteLabelData } from "./WhiteLabelData";
import whiteLabel from "./whiteLabel";

const rootReducers = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    support: supportReducer,
    rebrand: rebrandReducer,
    social: socialReducer,
    videos: videoReducer,
    whiteLabel,
    wl: WhiteLabelData,
    tempVar: variantReducer
})

export default rootReducers;