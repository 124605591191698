import React from 'react';

const TemplateWatermark = (props) => {
    return (
        <>
            {
                props.watermark.src !== "" ?
                    <div className={`watermark-op logo-${props.watermark.postion} logo-style`}><img src={props.watermark.src} alt="watermark" /></div>
                    : ''
            }
        </>
    );
}

export default TemplateWatermark;
