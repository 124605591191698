import React from "react";
import { Helmet } from "react-helmet";
import PrivacyEmail from "./PrivacyEmail";
import PrivacyGdpr from "./PrivacyGdpr";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Title from "../Title";

const Privacy = () => {
    return (
        <>
            <Title title="Privacy" />
            <Navbar />

            <section className="gdpr-sec">
                <div className="container">
                    <PrivacyEmail />
                    <PrivacyGdpr />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Privacy;