import styled from "styled-components";
export const Props_Cons1style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img {
    max-height: 100%;
   
  }
  
.template-preview {
    width: 100%;
    position: absolute;
    top: 0;
}
.shape {
    position: absolute;
    top: 45px;
    left: 47.5%;
}
.center-shape {
    width: 23px;
    height: 410px;
    background: linear-gradient(90deg,#4A4A4A 50%,#A1A1A0 50%);
    box-shadow: 1.5px -1px 5px #4a4a4a;
    position: absolute;
}
.arrow-right {
    width: 18rem;
    height: 3rem;
    background: #FF4B4B;
    clip-path: polygon(0% 0%,90% 0%,100% 50%,90% 100%,0% 100%);
    position: absolute;
    left: 1rem;
}

.shadow-2 {
    filter: drop-shadow(18px 5px 3px rgba(50, 50, 0, 0.5));
    position: absolute;
    top: 5px;
}

.shadow-1 {
    filter: drop-shadow(-18px 5px 3px rgba(50, 50, 0, 0.5));
    position: absolute;
    top: 5px;
}
.arrow-left {
    width: 18rem;
    height: 3rem;
    background: #FFD02B;
    clip-path: polygon(30px 0%,100% 0%,100% 100%,30px 100%,0 50%);
    position: absolute;
    top: 3px;
    left: -17rem;
}

.text-wrappers {
    position: absolute;
    top: 130px;
    left: 0.5%;
}

.text-content {
    width: 270px;
}

.text-wrappers-2 {
    position: absolute;
    top: 130px;
    left: 55%;
}

p{
    margin-bottom:0;
}

@media(max-width: 1199.98px){ 
    .text-wrappers-2 {
        position: absolute;
        top:115px;
        left: 55%;
    }
    
    .text-wrappers {
        position: absolute;
        top:115px;
        left: 1%;
    }
    .center-shape {
        width: 23px;
        height: 285px;
    }
    
    .shape {
        position: absolute;
        top: 45px;
        left: 48%;
    }
    .arrow-right {
        width: 15rem;
        height: 2.5rem;
        
    }
    .arrow-left {
        width: 15rem;
        height: 2.5rem;
        top: 3px;
        left: -15rem;
    }
    .arrow-left p{
        font-size:20px!important
    }
    .arrow-right p{
        font-size:20px!important
    }
    .text-content {
        width: 215px;
    }
    
  }

`;