import styled from "styled-components";
export const Intro5style = styled.div`

.template-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  text-align:center;
}

.template-wrapper img{
  max-height:100%;
}

.template-preview {
  position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.media-border{
    position: absolute;
    top: 50px;
    left: 9px;
    border: 2px solid #fff;
    width: 290px;
    border-radius: 50%;
    height: 290px;
    background: #fff;
    opacity: 0.2;
}
.media-bg{
    position: absolute;
    top: 76px;
    left: 34px;
    border: 2px solid #fff;
    width: 240px;
    border-radius: 50%;
    height: 240px;
    background: #fff;
    opacity: 0.5;
}

.product-wrappers {
  height: 190px;
  position: absolute;
  top: 98px;
  left: 50px;
}
 .product-wrappers-2 {
  height: 130px;
  position: absolute;
  top: 131px;
  left: 59px;
}

.product-wrappers img{
  height:100%
}

.video-player{
  width:191px!important;
  max-height:100%;
}

.video-player video{
  object-fit:unset;
}

.text-wrappers-1 {
   
    margin-top: 53px;
    margin-left: 38px;
    height: auto;
    width: 77%;
    height: auto;
}

.text-wrappers-1 h1{
   width:100%;
   height:100%;
   overflow:hidden;
   margin-bottom:0px;
}
 
.text-wrappers-2 {
 
  margin-top: 53px;
  margin-left: 38px;
  height: auto;
  width: 77%;
  height: auto;
}
.text-wrappers-2 p{
  width:100%;
  height:auto;
  margin-bottom:0px;
  overflow:hidden;
}


@media (max-width: 1199.98px) { 

  .media-border {
    position: absolute;
    top: 50px;
    left: 9px;
    border: 2px solid #fff;
    width: 250px;
    border-radius: 50%;
    height: 250px;
    background: #fff;
    opacity: 0.2;
  }
 .media-bg {
    position: absolute;
    top: 76px;
    left: 34px;
    border: 2px solid #fff;
    width: 200px;
    border-radius: 50%;
    height: 200px;
    background: #fff;
    opacity: 0.5;
 }
  .product-wrappers {
    height: 170px;
    position: absolute;
    top: 90px;
    left: 48px;
  }

  .video-player {
    width: 173px!important;
    max-height: 100%;
   }
 
   .product-wrappers-2 {
    height: 119px;
    position: absolute;
    top: 117px;
    left: 47px;
  }

  .product-wrappers {
    height: 135px;
    position: absolute;
    top: 98px;
    left: 55px;
  }

}

`;