export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const SET_LOADER = "SET_LOADER";
export const REMOVE_LOADER = "REMOVE_LOADER";
export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const CLEAR_SOCIAL_ACCOUNTS = "CLEAR_SOCIAL_ACCOUNTS";
export const CHANGE_PROFILE_PICTURE = "CHANGE_PROFILE_PICTURE";
export const CLEAR_TEMPLATE_VIDEO = "CLEAR_TEMPLATE_VIDEO";
export const FETCH_CLIENT_TEAM_ACCOUNT = "FETCH_CLIENT_TEAM_ACCOUNT";
export const CLEAR_CLIENT_TEAM_ACCOUNT = "CLEAR_CLIENT_TEAM_ACCOUNT";
export const LOAD_TEMPLATE_ID = "LOAD_TEMPLATE_ID";
export const LOAD_TEMPLATE = "LOAD_TEMPLATE";
export const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";
export const FETCH_UPLOADED_DATA = "FETCH_UPLOADED_DATA";
export const FETCH_UPLOADED_AUDIO = "FETCH_UPLOADED_AUDIO";
export const FETCH_UPLOADED_LOGO = "FETCH_UPLOADED_LOGO";
export const FETCH_ALL_PROJECT = "FETCH_ALL_PROJECT";
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const CLEAR_DASHBOARD_DATA = "CLEAR_DASHBOARD_DATA";
export const PUT_SEEK_VALUE = "PUT_SEEK_VALUE";
export const FETCH_TTS_DATA = "FETCH_TTS_DATA";
export const FETCH_SUPPORT_VIDEO = "FETCH_SUPPORT_VIDEO";
export const FETCH_SUPPORT_ARTICLE = "FETCH_SUPPORT_ARTICLE";
export const CLEAR_SUPPORT_DATA = "CLEAR_SUPPORT_DATA";
export const GET_ONE_ARTICLE = "GET_ONE_ARTICLE";
export const GET_ARTICLE_CONTENT = "GET_ARTICLE_CONTENT";
export const NORMAL_TIMELINE = "NORMAL_TIMELINE";
export const RECONFIGURE_TIMELINE = "RECONFIGURE_TIMELINE";
export const RESELECT_TIMELINE = "RESELECT_TIMELINE";
export const REARRANGE_TIMELINE = "REARRANGE_TIMELINE";
export const DELETE_REARRANGE_TIMELINE = "DELETE_REARRANGE_TIMELINE";
export const SET_TIMELINE_PLAYTIME = "SET_TIMELINE_PLAYTIME";
export const ADD_TIMELINE = "ADD_TIMELINE";
export const FETCH_TTS_AUDIO = "FETCH_TTS_AUDIO";
export const CLEAR_TTS = "CLEAR_TTS";
export const CLEAR_NORMAL_TEMPLATE = "CLEAR_NORMAL_TEMPLATE";
export const SET_VERIFY_LOADER = "CLEAR_VERIFY_LOADER";
export const GET_BRANDING_DATA = "GET_BRANDING_DATA";
export const CLEAR_BRANDING_DATA = "CLEAR_BRANDING_DATA";
export const FETCH_MERGED_VIDEOS = "FETCH_MERGED_VIDEOS";
export const LOAD_MERGE_DATA = "LOAD_MERGE_DATA";
export const SET_DROPDOWN_DATA = "SET_DROPDOWN_DATA";
export const OPEN_CLOSE_MODAL = "OPEN_CLOSE_MODAL";

// All White Label
export const FETCH_WHITE_LABEL_DETAILS = "FETCH_WHITE_LABEL_DETAILS";
export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_WHITELABEL_TEMPLATE = "FETCH_WHITELABEL_TEMPLATE";
export const SET_GENERAL_DATA = "SET_GENERAL_DATA";
export const SET_PRICE = "SET_PRICE";
export const SET_PAYMENT = "SET_PAYMENT";
export const SET_SMTP = "SET_SMTP";
export const SET_TEMPLATE_ID = "SET_TEMPLATE_ID";
export const SET_FILE = "SET_FILE";
export const FETCH_WHITELABEL_MEMBERSHIP = "FETCH_WHITELABEL_MEMBERSHIP";
export const CLEAR_WHITELABEL_DATA = "CLEAR_WHITELABEL_DATA";
export const FETCH_LANGUAGE_FOR_TRANSLATE = "FETCH_LANGUAGE_FOR_TRANSLATE";
