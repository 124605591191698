import styled from "styled-components";
export const Intro1style = styled.div`

.template-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  text-align:center;
}

.template-wrapper img{
  max-height:100%;
}

.template-preview {
  position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}

.product-wrappers {
  height: 234px;
  position: absolute;
  top: 50px;
  left: 89px;
}

.product-wrappers img{
  height:100%
}

.video-player{
  width:220px!important;
  max-height:100%;
}

.video-player video{
  object-fit:unset;
}

.text-wrappers-1 {
    // position: absolute;
    // top: 74px;
    // right: 33px;
    // color: #fff;
    // margin-bottom: 20px;
    margin-top: 53px;
    margin-left: 38px;
    height: auto;
    width: 77%;
    height: auto;
}

.text-wrappers-1 h1{
   width:100%;
   height:100%;
   overflow:hidden;
   margin-bottom:0px;
}
 
.text-wrappers-2 {
  // position: absolute;
  // top: 135px;
  // right: 33px;
  // color: #fff;
  margin-top: 53px;
  margin-left: 38px;
  height: auto;
  width: 77%;
  height: auto;
}
.text-wrappers-2 p{
  width:100%;
  height:auto;
  margin-bottom:0px;
  overflow:hidden;
}


@media (max-width: 1199.98px) { 

  .product-wrappers {
    height: 172px;
    position: absolute;
    top: 50px;
    left: 89px;
  }
   .video-player {
    width: 173px!important;
    max-height: 100%;
   }


}

`;