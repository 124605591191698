import React, {useState} from "react";
import {useSelector} from "react-redux";
import queryString from "query-string";
import {Link} from "react-router-dom";

const TrainingContents = ({location}) => {

    const supportData = useSelector(state => state.support);
    const articleId = queryString.parse(location.search).id;

    const [articles, setArticles] = useState(supportData.articles);

    return(
        <>
            <div className="col-md-8" style={{margin: "115px 0px 60px 0px"}}>
                <div className="popular-topics-content">
                    <Link className="vidoebtn" to="/help-and-support"><span className="mr-1">
                        <i className="fas fa-arrow-left"></i></span> Back to Video Training
                    </Link>

                    <div className="tab-content mt-4" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                            {
                                articles.length > 0 ?
                                    articles.map((article,index)=>{
                                        return(
                                            article.id === articleId ?
                                                article.items.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <h4 key={index} className="mt-4 text-white ">
                                                                <Link to={`/support-article?id=${articleId}&art_id=${item.id}`}>
                                                                    {item.title}
                                                                </Link>
                                                            </h4>
                                                            <div key={index} className="mt-4">
                                                                <img src={item.feature_url} className="img-fluid" alt="" />
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            : ''
                                        )
                                    })
                                : ''
                            }

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingContents;