import React, { useState } from 'react';
import { Intro3style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });

    return (
        <Intro3style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    data={props.backgroundData.fileUrl}
                    mediaType="bgImage"
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="text-wrappers">
                                    <TextEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        layerStatus={props.templateText[0].is_selected}
                                        textStatus={props.templateText[0].text.is_selected}
                                        layerId={props.templateText[0].id}
                                        text={props.templateText[0].text.text}
                                        style={props.templateText[0].text.style}
                                        maxLimit={props.textVarient.length > 0 ? props.textVarient[0].maxLimit : 0}
                                        templateNumber={props.templateNumber}
                                        setTextLength={props.setTextLength}
                                        setMaxLimit={props.setMaxLimit}
                                        blockType={
                                            {
                                                tag: 'p',
                                            }

                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className={`product_wrapper  ${layer.image ? 'image-bg-border' : ''}`}>
                                    <MediaEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        mediaType="pImage"
                                        data={props.productImage}
                                        type={props.productImage.includes("mp4") ? 'video' : 'image'}
                                        setSelectImageLayer={props.setSelectImageLayer}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="text-wrappers-2">
                                    {props.templateText[1] !== undefined ?
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[1].is_selected}
                                            textStatus={props.templateText[1].text.is_selected}
                                            layerId={props.templateText[1].id}
                                            text={props.templateText[1].text.text}
                                            style={props.templateText[1].text.style}
                                            // maxLimit={props.textVarient.length > 0 ?  props.textVarient[1].maxLimit : 0}
                                            templateNumber={props.templateNumber}
                                            setTextLength={props.setTextLength}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'p',
                                                }

                                            }
                                        />
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Intro3style >
    );

};
export default Template;