import styled from "styled-components";
export const Bonuses2style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
}

.template-wrapper img{
    max-height:100%;
}

.template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.head-box{
    margin-top:2px;
}
.head-box h1{
    color:#fff;
    font-size:28px;
}

.text-content{
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  margin-left: 10px;
}

.text-wrappers-1{
    margin-top:12px
}

.text-wrappers-2{
   margin-left:10px;
   margin-top:-28px;
   width:465px;
 
}
.text-wrappers-2 p{
   
   margin-bottom:0;
 
}

.Bonuses-list {
    position: absolute;
    top:7px;
}

.shapes{
  display: flex;
  align-items: center;
  position: relative!important;
  top: 16px;
  width: 100px;
  height: 70px;
  margin-right:5px;

 }
 .shape-1{
    width: 33px;
    height: 44px;
    background: #fff;
    transform: rotate(50deg);
    position: absolute!important;
    top: -4px;
 } 
 .shape-2{
    width: 50px;
    height: 44px;
    background: #57737A;
    transform: rotate(34deg);
    position: absolute;
    top: -3px;
    left: 8px;
}
 .shape-3{
    width: 61px;
    height: 49px;
    background: #fff;
    transform: rotate(67deg);
    position: absolute;
    top: -11px;
    left: 18px;
}
 .shape-4{
    width:81px;
    height: 53px;
    background: #57737A;
    transform: rotate(0deg);
    position: absolute;
    top: -17px;
    left: 31px;
}

@media (max-width: 1199.98px) { 
    .text-content {
        margin-bottom: 1px;
    }
    .head-box h1{
        font-size:22px;
    }
    
    .text-wrappers-2 {
        margin-left: 30px;
        margin-top: -22px;
        width: 340px;
    }

    .Bonuses-list {
        position: absolute;
        top: -4px;
    }
    .jgxCHf .shapes {
        height: 65px;
    }
    .head-box{
        margin-top:0px;
    }
    .shape-2 {
        width: 35px;
        height: 40px;
        top: -6px;
    }
    .shape-3 {
        width: 49px;
        height: 49px;
    }    
    .shape-4 {
        width:86px;
        height: 51px;
    }

}

`;