import React from "react";
import VideoCard from "../project/VideoCard";
import { Link } from "react-router-dom";

const DashboardContent = () => {
    return (
        <>
            <section className="banner-video-project">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video-project text-white align-items-center d-flex justify-content-between">
                                <h5 className="m-0">Video <span>Projects</span></h5>
                                <Link to="/projects">View all <span><i className="fas fa-chevron-circle-right"></i></span></Link>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <VideoCard topProject={4} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardContent;