import React, { useState } from 'react';
import { Testimonial6style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';
import quote from '../../../../../images/testi4_quote.png';
import { MdFormatQuote } from "react-icons/md"

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Testimonial6style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview">
                    <div className="container" style={{ width: "640px" }}>
                        <div className='row m-0'>
                            {props.templateText.map((curElem) => {
                                return (
                                    <div className="col-sm-6">
                                        <div className={`slide-${curElem.id}`}>

                                            <div className="image-wrapper">
                                                <div className={`${layer.image && curElem.is_selected ? 'image-bg-border' : ''} card-img-box-${curElem.id}`} style={{width:"100%",height:"100%"}}>
                                                    <MediaEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        mediaType="pImage"
                                                        layerId={curElem.id}
                                                        data={curElem.image}
                                                        type={curElem.image.includes("mp4") ? 'video' : 'image'}
                                                        setSelectImageLayer={props.setSelectImageLayer}
                                                    />
                                                </div>
                                            </div>

                                            <div className="texts-wrappers">
                                                <div className="quote">
                                                    <MdFormatQuote style={{ fontSize:"25px"}}/>
                                                </div>
                                                <div className="quote-2">
                                                    <MdFormatQuote style={{ fontSize:"25px"}}/>
                                                </div>
                                                <div className="line-1"></div>
                                                <div className="line-2"></div>
                                                <div className="line-3"></div>
                                                <div className="line-4"></div>
                                                <div className="line-5"></div>
                                                <div className="line-6"></div>
                                                <div className="employee-name">
                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.name.is_selected}
                                                        layerId={curElem.id}
                                                        textId={curElem.name.id}
                                                        text={curElem.name.text}
                                                        style={curElem.name.style}
                                                        type={props.templateType}
                                                        templateNumber={props.templateNumber}
                                                        setTextLength={props.setTextLength}
                                                        setMaxLimit={props.setMaxLimit}
                                                        blockType={
                                                            {
                                                                tag: 'h1',
                                                            }

                                                        }
                                                    />
                                                </div>
                                                <div className="employee-designation mt-2">

                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.designation.is_selected}
                                                        layerId={curElem.id}
                                                        textId={curElem.designation.id}
                                                        text={curElem.designation.text}
                                                        style={curElem.designation.style}
                                                        type={props.templateType}
                                                        setTextLength={props.setTextLength}
                                                        setMaxLimit={props.setMaxLimit}
                                                        templateNumber={props.templateNumber}
                                                        blockType={
                                                            {
                                                                tag: 'span',
                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className="employee-para mt-2">
                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.text.is_selected}
                                                        layerId={curElem.id}
                                                        textId={curElem.text.id}
                                                        text={curElem.text.text}
                                                        style={curElem.text.style}
                                                        templateNumber={props.templateNumber}
                                                        type={props.templateType}
                                                        setTextLength={props.setTextLength}
                                                        setMaxLimit={props.setMaxLimit}
                                                        blockType={
                                                            {
                                                                tag: 'p',
                                                            }

                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>

                    </div>
                </div>
            </div>

        </Testimonial6style >

    );

};
export default Template;