import React, { useState } from 'react';
import{Demo1style} from './style';
import MediaEditor from '../../../Preview/MediaEditor';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Demo1style>
            <div className="template-wrapper image-bg-border">
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={"video"}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
            </div>
        </Demo1style>

    );
}

export default Template;
