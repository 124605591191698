import styled from "styled-components";
export const List6style = styled.div`
.flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
}

.template-wrapper img{
     max-height:100%;
}

.template-preview {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
}

.head-box {
    position: absolute;
    top: -8px;
    left: 30%;
}
.head-box img{
   width:265px;
  }

.Feature-list {
    width:100%;
    height:auto;
    margin-top:40px;
    position:absolute;
    top:85px;
    
}
.myStyle{
    color: ' #fff',
    fontSize: '90px',
    fontFamily: '',
    textAlign: ' center',
    textTransform: 'uppercase',
}
.Feature-list-2 {
    width:100%;
    height:auto;
    margin-top:40px;
    position:absolute;
    top:85px;
    
}

.text-wrapper-1 {
    width:285px;
    position: relative;
    padding-left:10px;
}


.text-wrapper-2 {
    width:285px;
    position: relative;
    padding-left:10px;
}

.Feature-list p {
    font-size: 14px;
    text-align: center;
    
}

.Feature-list-2 p {
    font-size: 14px;
    text-align: center;
}

.text-wrapper-2 {
    margin-top: 22%;
}

@media (max-width: 1199.98px) { 
  
    .text-wrapper-1 {
        width:225px;
    }
  
    .text-wrapper-2 {
        width:225px;
    }
   

}


`;