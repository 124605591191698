import styled from "styled-components";
export const Bonuses6style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
}

.template-wrapper img{
    max-height:100%;
}

.template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.head-box{
    margin-top:2px;
}
.head-box h1{
    color:#fff;
    font-size:28px;
}

.bonus-wrapper-1{
    position: absolute;
    top: -27px;
    left: 15px;
}
.bonus-shape-1{
    clip-path: polygon(50% 0%,100% 50%,50% 100%,0% 50%);
    width: 90px;
    height: 90px;
    background:#083F6A;
    left: 7px;
}
.bonus-rhombus-1{
    clip-path: polygon(50% 0%,100% 50%,50% 100%,0% 50%);
    width: 90px;
    height: 90px;
    background: #F5418F;
    position: absolute;
    left: -5px;
}

.bonus-rhombus-3{
    width: 69px;
    height: 72px;
    position: absolute;
    left: -3px;
    top: 10px;
    border-left: 4px solid #083F6A;
    border-top: 4px solid #083F6A;
    transform: rotate(316deg);
 }


 .bonus-wrapper-2{
    position: absolute;
    top: 60px;
    left: 51px;
}

.text-wrappers-2::after{
    content: "";
    position: absolute;
    bottom: 17px;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right,#F5418F 1%,#083F6A 9%);
    display: block;
}

 .bonus-wrapper-3{
    position: absolute;
    top: 141px;
    left: 94px;
}

 .bonus-wrapper-4{
    position: absolute;
    top: 220px;
    left: 140px
}

.bonus-shape-2{
    width:380px;
    height: 60px;
    position: absolute;
    left: 50px;
    top: 20px;
}


.track-sm{
    display:none;
}
.text-wrappers-2{
    margin-left: 54px;
    width: 370px;
    height:auto;
    display:flex;

}
 .text-wrappers-2 p{
    Padding:5px 5px;
    margin-bottom:0px;
    height:auto;
    width:100%;
    flex-wrap:wrap;
 }


@media (max-width: 1199.98px) { 
    .track-sm{
        display:block;
    }
    .track-lg{
        display:none;
    }

    svg{
        width:90px!important;
        height:90px!important;
        position: absolute;
        left: 10px;
        top: 0px;
    }

    .head-box{
        margin-top:0px;
    }
    .head-box h1{
        font-size:30px;
    }

    .track {
        stroke-dasharray: 124px;
        stroke-dashoffset: -28;
        position: absolute;
    }
    .circle {
        position: absolute;
        top: 10px;
        left: 21px;
        width: 60px;
        height: 60px;
    }
    .bonus-shape-2 {
        width: 304px;
        height: 52px;
        position: absolute;
        left: 50px;
        top: 12px;
    }
     .bonus-shape-3 {
        top: 12px;
        height:52px;
        left:357px;
    }
    .bonus-shape-4 {
        top: 12px;
        height:52px;
        left:374px;
    }
    .bonus-shape-5 {
        top: 12px;
        height:52px;
        left:391px;
    }
    .bonus-wrapper-1{
        position: absolute;
        top: -22px;
        left: -3px;
    }
    
     .bonus-wrapper-2{
        position: absolute;
        top: 43px;
        left: 34px;
    }
    
     .bonus-wrapper-3{
        position: absolute;
        top: 109px;
        left: 75px;
    }
    
     .bonus-wrapper-4{
        position: absolute;
        top: 177px;
        left: 113px;
    }
    .text-wrappers-2{
        margin-left:33px;
        width:269px;
        height:auto;
    
    }
     .text-wrappers-2 p{
        height:auto;
        width:100%;
     }
    
    
}


`;