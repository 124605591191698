import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav } from "react-bootstrap";
import { updateCampaingName } from '../../../actions/videoAction';
import iconSave from "../../../images/icon-save.svg";
import iconrender from "../../../images/icon-render.svg";
import iconStory from "../../../images/icon-story.svg";
import iconText from "../../../images/icon-text.svg";
import iconMedia from "../../../images/icon-media.svg";
import iconAudio from "../../../images/icon-audio.svg";
import iconSettings from "../../../images/icon-settings.svg";
import iconCredits from "../../../images/icon-credits.svg";
import axios from 'axios';
import { baseURL } from '../../../global/global';
import { setAlert } from '../../../actions/alert';
import { useHistory } from 'react-router-dom';
import Alert from '../../Alert';
import { Conclusion3style } from '../Templates/Conclusion/3/style';


const EditorHeader = ({ video }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(state => state.auth)

    const [loaderSave, setLoaderSave] = useState(false);
    const [loadRender, setLoadRender] = useState(false);

    const onChangeCampaing = (e) => {
        dispatch(updateCampaingName(e.target.value))
    }

    const handleProject = (type) => {
        console.log(video.campaingName)
        if (video.campaingName) {

            if (type === 'save') {
                video.type = type
                setLoaderSave(true)
            }
            else if (type === 'render') {
                video.type = type
                setLoadRender(true)
            }

            axios({
                method: 'POST',
                url: `${baseURL}project-saved`,
                data: video,
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    if (type === 'save') {
                        dispatch(setAlert(res.data.message, 'success'))
                    }
                    else if (type === 'render') {
                        setLoadRender(false)
                        dispatch(setAlert(res.data.message, 'success'))
                        history.push('/projects')
                    }
                }
                setLoadRender(false)
                setLoaderSave(false)
            }).catch((error) => {
                setLoadRender(false)
                setLoaderSave(false)
                console.log(error)
            })

        }
        else {
            dispatch(setAlert('Please enter a campaing name', 'danger'))
        }
    }
    return (
        <>
            <Alert />
            <div className="campaignTop">

                <div className="campaignBar">
                    <div className="add-campaign mr-0 mr-md-4 ">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Add Campaign Name"
                            value={video.campaingName}
                            onChange={(e) => onChangeCampaing(e)}
                            style={{ color: "#fff", backgroundColor: "#000", borderRadius: "30px" }}
                        />
                    </div>
                    <button className="demoLink" onClick={() => handleProject('save')}>
                        {loaderSave ? < i className="fa fa-spinner fa-spin" /> : <img src={iconSave} alt='save-icon' />}

                        Save Project
                    </button>
                    <button className="demoLink red" onClick={() => handleProject('render')}>
                        {loadRender ? < i className="fa fa-spinner fa-spin" /> : <img src={iconrender} alt='render-icon' />}
                        Render
                    </button>
                </div>

                <div className="campaignNav">
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link eventKey="story">
                                <div>
                                    <span><img src={iconStory} alt="" /></span>
                                    <span>Script</span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="text">
                                <div>
                                    <span><img src={iconText} alt="" /></span>
                                    <span>Text</span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="media">
                                <div>
                                    <span><img src={iconMedia} alt="" /></span>
                                    <span>Media</span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="audio">
                                <div>
                                    <span><img src={iconAudio} alt="" /></span>
                                    <span>Voiceover</span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="backaudio">
                                <div>
                                    <span><img src={iconAudio} alt="" /></span>
                                    <span>Music</span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="settings">
                                <div>
                                    <span><img src={iconSettings} alt="" /></span>
                                    <span>Settings</span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="credits">
                                <div>
                                    <span><img src={iconCredits} alt="" /></span>
                                    <span>Credits</span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                              <Nav.Link eventKey="motion">
                                <div>
                                    <span><img src={iconMotion} alt="" /></span>
                                    <span>Motion</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item> */}
                        {/* <Nav.Item>
                              <Nav.Link eventKey="transition">
                                <div>
                                    <span><img src={iconTransition} /></span>
                                    <span>Transition</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item> */}
                    </Nav>
                </div>
            </div>
        </>
    );

}

export default EditorHeader;
