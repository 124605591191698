import React, { useState, useEffect } from 'react';
import { baseURL } from '../../global/global';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../actions/alert';
import InfiniteScroll from "react-infinite-scroll-component";
import Navbar from '../Navbar';
import { FiTrash2 } from "react-icons/fi";
import './reel.css'
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Title from '../Title';
const ReelMerge = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [videoData, setVideoData] = useState([])
    const [mergeVideos, setMergeVideos] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(false);
    const [loader, setLoader] = useState({
        scrollLoader: false,
        mergeLoader: false,

    });
    const [totalRecord, setTotalRecord] = useState(0)
    const [mergeData, setMergeData] = useState({
        data: [],
        name: '',
        preview_image: false,
        ratio: '16:9',
        user_id: auth.user.id
    })

    const fetchProject = (total) => {
        const data = { "totalShowRecord": total }
        setLoader({
            ...loader,
            scrollLoader: true
        })
        axios({
            method: 'POST',
            url: `${baseURL}project-fetchs`,
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': JSON.parse(localStorage.getItem('state')).auth.token
            }
        }).then((res) => {
            console.log(res)
            if (res.data.status === true) {
                let array = res.data.data
                let myArr = []
                array.forEach((curElem) => {
                    if (curElem.status === "2") {
                        myArr.push(curElem)
                    }
                })
                if (res.data.total_show_record !== undefined) {
                    setVideoData(videoData.concat(myArr))
                    setTotalRecord(res.data.total_show_record)
                }
            }
            setLoader({
                ...loader,
                scrollLoader: false
            })
        }).catch((error) => {
            setLoader({
                ...loader,
                scrollLoader: false
            })
            console.log(error)
        })
    }


    useEffect(() => {
        fetchProject(0)
    }, [])

    const handleMerge = (item) => {

        let jsonObj = {
            id: item.campaignId,
            name: item.campaingName,
            preview_image: item.outputVideoThumb,
            url: item.outputVideo
        }

        setCurrentSlide(jsonObj);
        setMergeVideos([...mergeVideos, jsonObj]);
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader({
            ...loader,
            mergeLoader: true
        });
        mergeData.data = mergeVideos;
        mergeData.preview_image = mergeVideos[0].preview_image;
        axios({
            method: 'POST',
            url: `${baseURL}create-merge`,
            data: mergeData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                history.push('/projects');
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader({
                ...loader,
                mergeLoader: false
            });
        }).catch(err => {
            console.log(err)
            setLoader({
                ...loader,
                mergeLoader: false
            });
        })
    }
    const activeSlide = (video) => {
        setCurrentSlide(video)
    }

    const deleteSlide = (index) => {
        let removeArr = [...mergeVideos];
        removeArr.splice(index, 1);

        setMergeVideos(removeArr);
    }
    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            mergeVideos,
            result.source.index,
            result.destination.index
        );
        setMergeVideos(items);
    }
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    return (
        <>
            <Title title="Reel Merge" />
            <Navbar />

            <section className="siteWrap reelsitewrap">
                <div className="editorWrap reelmergeWrap">
                    <div className="editor-left">
                        <div className="row p-2 ">
                            <InfiniteScroll
                                dataLength={videoData.length}
                                hasMore={true}
                                next={() => fetchProject(totalRecord)}
                            >
                                {
                                    videoData.length > 0 ?
                                        videoData.map((item, index) => {
                                            return (

                                                <div className="col-6 cursor-pointer mb-2 px-1 text-center merge-wrap-img" key={index} onClick={() => handleMerge(item)}>
                                                    <div className="mediaList-single myImage-respo " style={{ textAlign: 'center' }}>
                                                        <img src={item.outputVideoThumb} alt="" className="img-fluid" style={{ width: "100%", height: "100%" }} />
                                                    </div>


                                                </div>
                                            )
                                        })
                                        : !loader.scrollLoader ?
                                            <div className="text-center" style={{ width: '100%' }}>
                                                <h5 className="text-white">No Videos Created</h5>
                                            </div> : ''
                                }
                                {loader.scrollLoader ?
                                    <div className="col-md-12">
                                        <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                    </div> : ''
                                }
                            </InfiniteScroll>

                        </div>
                    </div>
                    <div className="editor-right">
                        <div className="campaignBar">
                            <form method="post" onSubmit={(e) => handleFormSubmit(e)} className="col-md-12 d-flex flex-wrap align-items-center justify-content-center">
                                <div className="">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Add Campaign Name"
                                        defaultValue={mergeData.name}
                                        required
                                        onChange={(e) => setMergeData({ ...mergeData, name: e.target.value })}
                                    />
                                </div>

                                <button type="submit" className="demoLink" disabled={mergeVideos.length > 1 ? false : true} >
                                    <span className="mr-1">
                                        {loader.mergeLoader ? <i className="fa fa-spinner fa-spin" /> : <i className="fas fa-file-video" />}
                                    </span>
                                    Merge
                                </button>
                            </form>
                        </div>

                        <div className='canvasEditor'>
                            <div className="canvasEditor-main">
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={currentSlide.url}
                                    playing={true}
                                    muted={true}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </section >
            {/* Reel Tiles */}
            <section className="slide-sec-merge">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slide-box">
                                <DragDropContext onDragEnd={onSetRecorder}>
                                    <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided) => (
                                            <ul className="d-flex list-inline align-items-center m-0"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    mergeVideos.length > 0 ?
                                                        mergeVideos.map((video, index) => {
                                                            return (
                                                                <Draggable key={index} draggableId={`id_${index}`}
                                                                    index={index}>
                                                                    {(provided) => (
                                                                        <li
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            key={index}
                                                                            style={{ border: `${currentSlide.id === video.id ? '1px solid #FFA200' : ''}` }}
                                                                        >
                                                                            <div
                                                                                style={{ position: "relative", width: "100%", height: "100%" }}
                                                                                className='box-ar-wrapper'
                                                                            >
                                                                                <img
                                                                                    src={video.preview_image}
                                                                                    alt=""
                                                                                    className="img-fluid"
                                                                                    onClick={() => activeSlide(video)}
                                                                                />
                                                                                <FiTrash2
                                                                                    className="merge-delete"
                                                                                    color='#fff'
                                                                                    // style={{ cursor: 'pointer', color: `${index.is_selected ? '#ffa200' : ''}` }}
                                                                                    onClick={() => deleteSlide(index)}
                                                                                />
                                                                            </div>

                                                                        </li>
                                                                    )
                                                                    }
                                                                </Draggable>
                                                            )
                                                        })
                                                        : <li></li>
                                                }
                                                {provided.placeholder}
                                            </ul>
                                        )
                                        }
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Reel Tiles End */}

        </>
    );
}

export default ReelMerge;
