import React, { useState, useEffect } from 'react';
import { Tab, Nav, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { FiTrash2 } from 'react-icons/fi';
import closeModalx from "../../../images/closeModal.svg"
import axios from 'axios';
import { baseURL } from '../../../global/global';
import { setOutroStatus, removeOutro, updateIntroOutro } from '../../../actions/videoAction';
import { setAlert } from '../../../actions/alert';
import SweetAlert from 'react-bootstrap-sweetalert';
import HoverVideoPlayer from "react-hover-video-player";
import swal from 'sweetalert';
import sample from "../../../images/sample.png";
import { useHistory } from 'react-router-dom';



const Outro = ({ videoReel, video, socialLength }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(state => state.auth)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [outroData, setOutroData] = useState(false)
    const [outroArr, setOutroArr] = useState([])
    const [showDelete, setShowDelete] = useState(false);

    const [showDeleteVideoReel, setShowDeleteVideoReel] = useState(false);
    const [button, setButton] = useState('Delete');

    const [name, setName] = useState({
        outro: 'Upload Your Outro',
        outroToggle: false
    })
    const [thumb, setThumb] = useState({
        thumbOutro: false
    })
    const [loader, setLoader] = useState({
        outroLoader: false
    });
    const [intOut, setIntOut] = useState({
        id: false,
        type: '',
    })

    const selectOutro = (idOutro, type) => {
        let outroObj, nameData, thumbData, url

        if (type === "videoReel") {
            outroObj = videoReel.find(({ project_id }) => project_id === idOutro)
            url = outroObj.prview_video_url
            nameData = outroObj.project_name
            thumbData = outroObj.preview_image_url
        }
        else {
            outroObj = outroArr.find(({ id }) => id === idOutro)
            url = outroObj.url
            nameData = outroObj.name
            thumbData = outroObj.poster
        }

        setName({
            ...name,
            outro: nameData,
            outroToggle: false
        })
        setThumb({
            ...thumb,
            thumbOutro: thumbData
        })
        handleClose()
        dispatch(updateIntroOutro('UPDATE_OUTRO', url))
    }
    const connectVideoReel = () => {
        setShowDeleteVideoReel(true);
    }
    const onConfirmVideoReel = () => {
        setName({
            ...name,
            introToggle: false
        })
        history.push("/integration")
    }

    const onConfirm = () => {
        handleDelete(intOut.id, intOut.type)
    }


    const openDeletePopUp = (e, id, type) => {
        e.stopPropagation()
        setIntOut({
            ...intOut,
            id: id,
            type: type
        })
        setShowDelete(true);
    }

    useEffect(() => {
        if (video.data) {
            setOutroData(video.data.outro)
        }
    }, [video])

    const fetchOutro = () => {
        const video = { type: "outro" }
        axios({
            method: 'POST',
            url: `${baseURL}fetch-user-uploaded-file`,
            data: JSON.stringify(video),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                let val = res.data.data
                val = val.reverse()
                setOutroArr(val)
            }
            else {
                if (res.data.data.length === 0) {
                    setOutroArr(res.data.data)
                }
            }

        }).catch(err => {
            console.log(err)
        })

    }

    const handleLoader = (status) => {
        setLoader({
            ...loader,
            outroLoader: status
        });
    }

    const onInputVideo = (e, type) => {
        if (e.target.files[0].type === "video/mp4") {
            const allowedSize = 20000000;
            if (e.target.files[0].size < allowedSize) {
                handleLoader(true, type)
                let formData = new FormData();
                formData.append('file', e.target.files[0]);
                formData.append('type', type);
                axios({
                    method: 'POST',
                    url: `${baseURL}upload-user-media`,
                    data: formData,
                    headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
                }).then(res => {
                    if (res.data.status === true) {
                        handleIntroOutro(res.data)
                        handleLoader(false, type)
                    }
                    else {
                        dispatch(setAlert(res.data.message, 'danger'));
                        handleLoader(false, type)
                    }
                }).catch(error => {
                    handleLoader(false, type)
                    console.log(error)
                })
            } else {
                swal("Oops!", "Max allowed size for video is 20MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type ");
        }
    }

    const handleStatusIntroOutro = (e) => {
        dispatch(setOutroStatus(e.target.checked))
        if (e.target.checked === false) {
            dispatch(removeOutro())
            setThumb({
                ...thumb,
                thumbOutro: false
            })
            setName({
                ...name,
                outro: 'Upload Your Outro'
            })
        }
    }

    const handleDelete = (id) => {
        setButton("Deleting...")
        let formData = new FormData();
        formData.append('id', id);
        axios({
            method: 'POST',
            url: `${baseURL}delete-media-file`,
            data: formData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchOutro()
                dispatch(setAlert(res.data.message, 'success'));
            }
            setButton("Delete")
            setShowDelete(false);
        }).catch(err => {
            dispatch(setAlert('Getting server error', 'danger'));
            setShowDelete(false);
            console.log(err)
        })
    }
    const handleIntroOutro = (data) => {
        const src = data.path
        let actionType
        fetchOutro()
        actionType = 'UPDATE_OUTRO'
        setThumb({
            ...thumb,
            thumbOutro: data.thumbnial
        })
        setName({
            ...name,
            outro: data.name
        })
        dispatch(updateIntroOutro(actionType, src))
    }

    useEffect(() => {
        fetchOutro()
    }, []);

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title mb-4">
                    <h2>Outro</h2>
                    <div className="switch-single">
                        <label className="switch">
                            <input
                                type="checkbox"
                                onChange={(e) => handleStatusIntroOutro(e, 'outro')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {outroData.enable ?
                    <>
                        <div className="fileUpload" style={outroData.src ? { color: '#ffa200' } : {}}>
                            <i className="fa fa-cloud-upload" aria-hidden="true"></i> {name.outro.length > 23 ? `${name.outro.slice(0, 23)}...` : name.outro}
                            <input
                                type="file"
                                className='cursor-pointer'
                                onChange={(e) => onInputVideo(e, 'outro')}
                            />
                        </div>
                        <div className="itemDisp">
                            <div className="itemDisp-left text-center">
                                <ul>
                                    <li style={{ position: 'relative' }}>
                                        {
                                            loader.outroLoader ? <i className="fa fa-spinner fa-spin loader-center" />
                                                : <img
                                                    src={thumb.thumbOutro ? thumb.thumbOutro : sample}
                                                    alt=""
                                                    className="uploadImg"
                                                />
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className='itemDisp-right'>
                                <button onClick={handleShow}>Select Intro video from Your account +</button>
                            </div>
                            <Modal show={show} onHide={handleClose}>
                                <button className='model-close-button' onClick={handleClose}>
                                    <img src={closeModalx} alt="" />
                                </button>
                                <Modal.Body style={{ color: '#ffffff' }}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="tabInner">
                                                <Tab.Container id="" defaultActiveKey="intro-video">

                                                    <Nav variant="pills" className="inTabNav full credit-tab " style={{ width: "100%", height: "100%" }}>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="intro-video" style={{ fontSize: '15px' }}>Your uploaded Outro videos</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Videoreel-video" style={{ fontSize: '15px' }}>Videoreel Outro videos</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>

                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="intro-video">
                                                            <div className="innertab credit-modal">
                                                                <div className="row">
                                                                    {outroArr.length > 0 ?
                                                                        outroArr.map((curElem) => {
                                                                            return (
                                                                                <div
                                                                                    className="col-4"
                                                                                    key={curElem.id}
                                                                                    onClick={() => selectOutro(curElem.id)}
                                                                                >
                                                                                    <div className="credit-video-card" style={{
                                                                                        border: "none",
                                                                                    }}>
                                                                                        <div className="">
                                                                                            <>
                                                                                                <HoverVideoPlayer
                                                                                                    className="my-1 cursor-pointer "
                                                                                                    videoSrc={curElem.url}
                                                                                                    pausedOverlay={
                                                                                                        <img src={curElem.poster} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                                                                    }
                                                                                                    loadingOverlay={
                                                                                                        <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                                                                    }
                                                                                                />
                                                                                                <FiTrash2
                                                                                                    className='remove-media'
                                                                                                    onClick={(e) => openDeletePopUp(e, curElem.id, 'intro')}
                                                                                                />
                                                                                            </>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <div className='text-center' style={{ fontSize: 20 }}>
                                                                            You don't have uploaded Outro videos
                                                                        </div>}
                                                                </div>


                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>

                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="Videoreel-video">
                                                            <div className="innertab  credit-modal">
                                                                <div className="row">
                                                                    {videoReel.length > 0 ?
                                                                        videoReel.map((curElem, index) => {
                                                                            if (curElem.category === "outro") {
                                                                                return (
                                                                                    <div
                                                                                        className="col-4"
                                                                                        key={index}
                                                                                        onClick={() => selectOutro(curElem.project_id, "videoReel")}
                                                                                    >
                                                                                        <div className="credit-video-card m-2" style={{
                                                                                            border: "none",
                                                                                        }}>
                                                                                            <HoverVideoPlayer
                                                                                                className="cursor-pointer"
                                                                                                videoSrc={curElem.prview_video_url}
                                                                                                pausedOverlay={
                                                                                                    <img src={curElem.preview_image_url} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                                                                }
                                                                                                loadingOverlay={
                                                                                                    <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })
                                                                        :
                                                                        <div className='text-center center-msg'>
                                                                            {socialLength > 0 ?
                                                                                <div>
                                                                                    You haven't created your VideoReel Outro Videos yet
                                                                                </div> :
                                                                                <div className="alert alert-warning col-8" role="alert">
                                                                                    You haven't connected your VideoReel account yet. Click <a className="custom-anchor"
                                                                                        onClick={() => connectVideoReel()}
                                                                                    >Here</a> to connect and use those VideoReel Outro Videos
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText={button}
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="light"
                                    title="Are you sure?"
                                    onConfirm={() => onConfirm()}
                                    onCancel={() => setShowDelete(false)}
                                    focusCancelBtn
                                    show={showDelete}
                                >
                                    You want to delete this file
                                </SweetAlert>

                                <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Connect"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="light"
                                    title="Are you sure?"
                                    onConfirm={() => onConfirmVideoReel()}
                                    onCancel={() => setShowDeleteVideoReel(false)}
                                    focusCancelBtn
                                    show={showDeleteVideoReel}
                                >
                                    Please note that navigating from this page will discard all your customizations. Make sure you have saved the customizations.
                                </SweetAlert>

                            </Modal>
                        </div>
                    </> : ''}
            </div>

        </div>
    );
}

export default Outro;
