import React, { useState, useEffect } from 'react';
import { GiPauseButton } from 'react-icons/gi'
import { BsMicFill, BsPlayFill, BsStopFill } from 'react-icons/bs'
import axios from 'axios';
import { baseURL } from '../../../global/global';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import useRecorder from "./MyRecording";
import AudioFiles from '../AudioFiles';


let newIntervalId, music = false
const Recording = ({ selectedSlideIndex }) => {
    let [audioURL, status, recorder, setAudioURL, startRecording, stopRecording, pauseRecording, resumeRecording] = useRecorder();
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [play, setPlay] = useState(false)
    const [recordingData, setRecordingData] = useState([])
    const [selectedAudio, setSelectedAudio] = useState(false);
    const [second, setSecond] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [currentUrl, setCurrentUrl] = useState('');
    const [loader, setLoader] = useState({
        uploadLoader: false,
        fetchLoader: false
    })

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        if (type === "stop") {
            stopRecording()
            clearInterval(newIntervalId);
            let totalTime = timer.h * 3600 + timer.m * 60 + second
            setAudioDuration(totalTime)
            setSecond(0)
            setTimer({
                ...timer,
                h: 0,
                m: 0
            })
            return;
        }

    };
    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])


    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    // let clearPause
    const handlePlay = () => {
        if (audioURL) {
            const file = URL.createObjectURL(audioURL)
            music = new Audio(file)
            music.play()
            setPlay(true)


        }
    }

    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])
    const handlePause = () => {
        // clearTimeout(clearPause)
        music.pause()
        setPlay(false)

    }


    const removeMedia = async (id) => {
        let formData = new FormData();
        formData.append('id', id);

        await axios({
            method: 'POST',
            url: `${baseURL}delete-media-file`,
            data: formData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchRecording();
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(err => {
            dispatch(setAlert('Getting server error', 'danger'));
        })
    }

    const handleUpload = () => {
        if (audioURL !== '') {
            setLoader({
                ...loader,
                uploadLoader: true
            })
            const fileName = new File([audioURL], "audio.mp3");
            const formData = new FormData();
            formData.append('type', 'recording');
            formData.append('file', fileName);

            axios({
                method: 'POST',
                url: `${baseURL}upload-user-media`,
                data: formData,
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then(res => {
                dispatch(setAlert(res.data.message, "success"))
                fetchRecording()
                setAudioURL('')
                setLoader({
                    ...loader,
                    uploadLoader: false
                })

            }).catch(error => {
                console.log(error)
                setLoader({
                    ...loader,
                    uploadLoader: false
                })
            })
        }
    }

    const fetchRecording = async () => {
        const data = { type: 'recording' }
        setLoader({
            ...loader,
            fetchLoader: true
        })
        await axios({
            method: 'POST',
            url: `${baseURL}fetch-user-uploaded-file`,
            data: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                let val = res.data.data
                val = val.reverse()
                setRecordingData(val);
            }
            else {
                if (recordingData.length === 1) {
                    setRecordingData([])
                }
            }
            setLoader({
                ...loader,
                fetchLoader: false
            })
        }).catch(err => {
            console.log(err)
            setLoader({
                ...loader,
                fetchLoader: false
            })
        })
    }

    useEffect(() => {
        fetchRecording()
    }, [])

    return (
        <div className="innertab">
            <div className="innertab-scroll alt">
                <div className="voice-record">
                    <div className='row'>
                        <div className="col-12">

                            {/* <audio src={audioURL} controls /> */}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="voice-record-time text-center">
                                <span id="time-display" className="mb-2 text-white">
                                    {
                                        timer.h < 10 ? '0' + timer.h : timer.h
                                    }
                                    :
                                    {
                                        timer.m < 10 ? '0' + timer.m : timer.m
                                    }
                                    :
                                    {
                                        second < 10 ? '0' + second : second
                                    }
                                </span>
                            </div>

                            <div className="voice-record-img" style={{ display: 'flex' }}>
                                <div className="record-img rounded-circle text-center ">
                                    {status.start ?
                                        !status.pause ?
                                            <GiPauseButton
                                                fontSize={30}
                                                color="#000"
                                                className="cursor-pointer"
                                                onClick={() => handleClick("pause")}
                                            /> : <BsPlayFill
                                                fontSize={30}
                                                color="#000"
                                                className="cursor-pointer"
                                                onClick={() => handleClick("resume")}
                                            /> :
                                        <BsMicFill
                                            fontSize={30}
                                            color="#000"
                                            onClick={() => handleClick("start")}
                                            className="cursor-pointer"

                                        />
                                    }
                                </div>
                                <div className="record-img rounded-circle text-center text-dark">
                                    {status.start ?
                                        < BsStopFill
                                            fontSize={30}
                                            color="red"
                                            onClick={() => handleClick("stop")}

                                            className="cursor-pointer"
                                        /> : <BsStopFill
                                            fontSize={30}
                                            color="#000"
                                        />
                                    }
                                </div>
                            </div>
                            <div className="voice-block-bottom mt-4">
                                <div className="row">
                                    <div className="col-6">
                                        {play ? <button
                                            className="demoLink btn-block justify-content-center"
                                            onClick={handlePause}
                                        >
                                            Pause
                                        </button> :
                                            <button
                                                className="demoLink btn-block justify-content-center"
                                                onClick={handlePlay}
                                            >
                                                Play
                                            </button>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <button
                                            className="demoLink btn-block justify-content-center"
                                            onClick={handleUpload}
                                        >
                                            {loader.uploadLoader ?
                                                <> Uploading  <i className="fa fa-spinner fa-spin mx-1" /></> : 'Upload'
                                            }

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='row mt-5'>
                        <div className='mediaList'>
                            <ul className='p-2'>
                                {recordingData.length > 0 ?
                                    recordingData.map((item, index) => {
                                        return (
                                            <AudioFiles
                                                selectedSlideIndex={selectedSlideIndex}
                                                title="Uploaded VoiceOver"
                                                voiceOverName={item.name.length > 20 ? `${item.name.slice(0, 19)}...` : item.name}
                                                url={item.url}
                                                currentUrl={currentUrl}
                                                setCurrentUrl={setCurrentUrl}
                                                type="uploaded"
                                                removeMedia={removeMedia}
                                                selectedAudio={selectedAudio}
                                                setSelectedAudio={setSelectedAudio}
                                                id={item.id}
                                                key={index}
                                            />
                                        )
                                    }) : ''}
                                {
                                    loader.fetchLoader ?
                                        <div className="col-md-12 mt-2">
                                            <h4 className='text-center'><i className="fa fa-spinner fa-spin " style={{ color: '#ffa200' }} /></h4>
                                        </div>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

        </div >

    );
}

export default Recording;
