import styled from "styled-components";
export const Dedicated2style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }
  .video-player{
    width:160px!important;
    max-height:100%;
  }

.product-wrappers {
    height: 108px;
    position: absolute;
    top: 128px;
    left: 14px;
    z-index: 1;
 }

 .product-wrappers img{
   max-height:100%
 }


.head-box {
    width:100%;
    position: absolute;
    top: 45px;
    right: 44px;

}
.text-wrappers {
  width:100%;
  height:auto;
  position: absolute;
  right: 22px;
  top: 136px;
  display:flex;
}
.text-wrappers p {
  width:100%;
  height:auto;
  flex-wrap:wrap;
}


@media (max-width: 1199.98px) { 
  .text-wrappers {
    top: 120px;
 }
 .product-wrappers {
  height: 104px;   
 }

}


`;