import styled from "styled-components";
export const List2style = styled.div`
.flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
}

.template-wrapper img{
     max-height:100%;
}

.template-preview {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
}

.head-box {
    width:100%;
    position: absolute;
    top: 14px;
}
.head-box h1{
    text-transform: uppercase;
    font-size: 42px;
    font-weight: bold;
  }

.Feature-list {
    width:100%;
    height:auto;
    margin-top:40px;
    position:absolute;
    top:85px;
    
}
.myStyle{
    color: ' #fff',
    fontSize: '90px',
    fontFamily: '',
    textAlign: ' center',
    textTransform: 'uppercase',
}
.Feature-list-2 {
    width:100%;
    height:auto;
    margin-top:40px;
    position:absolute;
    top:85px;
    
}

.text-wrapper-1 {
    width:285px;
    position: relative;
    padding-left:10px;
}

.hr_line {
   width: 40%;
    color: #D50E34;
    height: 6px !important;
    display: inline-block;
    opacity: 1;
    position: absolute;
    left: 80px;
    top: -41px;
    border-radius: 20px;

}

.text-wrapper-2 {
    width:285px;
    position: relative;
    padding-left:10px;
}

.hr_line-2 {
    width: 40%;
    color: #D50E34;
    height: 6px !important;
    opacity: 1;
    position: absolute;
    left: 80px;
    bottom: -41px;
    border-radius: 20px;

}

.Feature-list p {
    font-size: 14px;
    text-align: center;
    
}

.Feature-list-2 p {
    font-size: 14px;
    text-align: center;
}

.text-wrapper-2 {
    margin-top: 25%;
}

@media (max-width: 1199.98px) { 
  
    .text-wrapper-1 {
        width:225px;
    }
  
    .text-wrapper-2 {
        width:225px;
    }
   

}


`;