import styled from "styled-components";
export const Testimonial3style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }
  
  .template-wrapper img{
    max-height:100%;
  }
  
  .template-preview {
    position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }

.card {
   border-radius: 0;
    width: 154px;
    border: none;

}

.card_1 {
    height: 320px;
    margin-top: 2px;
    margin-left: -5px;
    position: relative;
    background: #fff;
}
.row{
    padding-left:5px!important;
}
.col-md-3{
    padding:4px!important;
}

.image-wrapper {
    width: 90px;
    height: 135px;
    position: absolute;
    right: 6px;
    top: 6px;
}

.card_1 .image-wrapper img {
  height:100%!important;
  width:100%!important;
}

.name_wrapper {
    width: 51px;
    position: absolute;
    left: 5px;
    top: 29px;
}

.name_wrapper h1 {
    width: 100%;
}

.line {
    content: "";
    position: absolute;
    left: -23px;
    top: 19px;
    width: 38px;
    height: 2px;
    background-color: #970808;
    margin: auto;
    transform: rotate(270deg);
}

.circle {
    border-radius: 50% !important;
    width: 10px;
    height: 10px;
    background: #b1aeae;
    position: absolute;
    top: 44%;
    left: 3%;
}

.circle-2 {
    border-radius: 50% !important;
    width: 6px;
    height: 6px;
    background: #b1aeae;
    position: absolute;
    top: 36%;
    left: 24%;
}

.quote {
    position: absolute;
    top: 47%;
    left: 2px;
}

.quote img {
    
    width:47px;
}

.content {
    position: absolute;
    top: 56%;
    left: 11px;
    width: 136px;
}

.content p {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.content p::after {
    content: "";
    display: block;
    width: 15px;
    height: 2px;
    background-color: #0009;
    position: absolute;
    bottom: 10px;
}

.card_2 {
    height: 320px;
    margin-top: 27px;
    position: relative;
    margin-left: -4px;
    background: #fff;
}

.card_2 .image-wrapper img {
  height:100%!important;
  width:100%!important;
}

.card_3 {
    height: 320px;
    margin-top: 27px;
    margin-left: -4px;
    position: relative;
    background: #fff;
}

.card_3 .image-wrapper img {
  height:100%!important;
  width:100%!important;
}

.card_4 {
    height: 320px;
    margin-top: 15px;
    margin-left: -4px;
    position: relative;
    background: #fff;  
}

.card_4 .image-wrapper img {
  height:100%!important;
  width:100%!important;;
}

@media (max-width: 1199.98px) { 
    .card_1 {
        height: 272px;
        margin-top: 0px;
     }
    .card_2 {
        height: 272px;
        margin-top: 18px;
     }
    .card_3 {
        height: 272px;
        margin-top: 18px;
     }
    .card_4 {
        height: 272px;
        margin-top: 0px;
     }
    .card {
        width: 8rem;
    }
   .container{
       height:100%;
   }
   .row{
       height:100%;
   }
    .col-md-3{
        height:100%;
        padding:3px!important;
    }

    .name_wrapper {
        width: 53px;
        position: absolute;
        left: 5px;
        top: 33px;
    }
    .content {
        position: absolute;
        top:63%;
        left: 8px;
        width: 112px;
    }
    .image-wrapper {
        width: 63px;
        height: 120px;
    }
}


`;
