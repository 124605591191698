import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AddAccount from "./AddAccount";
import Title from "../Title";
import { useSelector } from "react-redux";

const AccountManagement = () => {
    const auth = useSelector(state => state.auth)
    const [membership, setMembership] = useState([]);

    useEffect(() => {
        if (auth.user.membership !== '' && auth.user.membership !== null) {
            setMembership(auth.user.membership.split('__'))
        }
    }, [])

    return (
        <>
            <Title title="Account Management" />
            <Navbar />

            <section className="add-account-sec">
                <div className="container">
                    {membership.includes("agency") || membership.includes("bundle") ?
                        <AddAccount />
                        :
                        <div className="text-center text-light mt-5">
                            <h5>You do not have access to this Feature. To get access please upgrade your <br />Membership</h5>
                        </div>
                    }
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AccountManagement;