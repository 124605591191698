import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const UpgradesMenu = (props) => {
   const auth = useSelector(state => state.auth)
   const [membership, setMembership] = useState([]);

   useEffect(() => {
      if (auth.user) {
         if (auth.user.membership !== '' && auth.user.membership !== null) {
            setMembership(auth.user.membership.split('__'))
         }
      }
   }, [auth])

   const handleWeek = (num) => {
      if (num === 1) {
         props.setW1(true)
         props.setW2(false)
         props.setW3(false)
         props.setW4(false)
      }
      else if (num === 2) {
         props.setW1(false)
         props.setW2(true)
         props.setW3(false)
         props.setW4(false)
      } else if (num === 3) {
         props.setW1(false)
         props.setW2(false)
         props.setW3(true)
         props.setW4(false)
      }
      else if (num === 4) {
         props.setW1(false)
         props.setW2(false)
         props.setW3(false)
         props.setW4(true)
      }
   }
   // className={`nav-link ${masterClass.w2 === true ? 'active show' : ''}`}

   return (
      <>
         <div className="col-md-4">
            <div className="upgrade-menu mt-5">
               <div className="nav flex-column " id="v-pills-tab" role="tablist" aria-orientation="vertical">

                  {membership.includes("masterclass") ?
                     <a className="nav-link cursor-pointer " data-toggle="collapse" data-target="#home-collapse" aria-expanded="false" aria-selected="false" href="#v-pills-masterclass">Master Class</a>
                     : ''}

                  <div className="collapse" id="home-collapse">
                     <ul className="list-unstyled">
                        <li onClick={() => handleWeek(1)}>
                           <a id="v-pills-masterclass-tab" aria-controls="v-pills-masterclass-1"
                              aria-selected="true" data-toggle="pill" href="#v-pills-masterclass-1"
                              role="tab" className={props.w1 ? ' nav-link' : 'nav-link'} >Week 1</a>
                        </li>
                        <li onClick={() => handleWeek(2)}>
                           <a id="v-pills-masterclass-tab" aria-controls="v-pills-masterclass-2"
                              data-toggle="pill" href="#v-pills-masterclass-2"
                              role="tab" aria-selected="false" className={props.w2 ? ' nav-link' : 'nav-link'}>Week 2</a>
                        </li>
                        <li onClick={() => handleWeek(3)}>
                           <a id="v-pills-masterclass-tab" aria-controls="v-pills-masterclass-3"
                              data-toggle="pill" href="#v-pills-masterclass-3"
                              role="tab" aria-selected="false" className={props.w3 ? ' nav-link' : 'nav-link'}>Week 3</a>
                        </li>
                        <li onClick={() => handleWeek(4)}>
                           <a id="v-pills-masterclass-tab" aria-controls="v-pills-masterclass-4"
                              data-toggle="pill" href="#v-pills-masterclass-4"
                              role="tab" aria-selected="false" className={props.w4 ? ' nav-link' : 'nav-link'}>Week 4</a>
                        </li>

                     </ul>
                  </div>
                  {membership.includes("agency") || membership.includes("bundle") ?
                     <>
                        <a className="nav-link" data-toggle="pill"
                           href="#v-pills-wlrebranding" role="tab" aria-controls="v-pills-wlrebranding"
                           aria-selected="false">Agency Rebranding</a>

                        <a className="nav-link" id="v-pills-business-tab" data-toggle="pill" href="#v-pills-business"
                           role="tab" aria-controls="v-pills-business" aria-selected="false">Business Finder</a>

                        <a className="nav-link" id="v-pills-fbtemplate-tab" data-toggle="pill"
                           href="#v-pills-fbtemplate" role="tab" aria-controls="v-pills-fbtemplate"
                           aria-selected="true">FB Templates</a>

                        <a className="nav-link" id="v-pills-udamy-tab" data-toggle="pill" href="#v-pills-udamy"
                           role="tab" aria-controls="v-pills-udamy" aria-selected="false">Acadeable</a>

                        <a className="nav-link" id="v-pills-client-tab" data-toggle="pill" href="#v-pills-client"
                           role="tab" aria-controls="v-pills-client" aria-selected="false">Client Contract</a>

                        <a className="nav-link" id="v-pills-reseller-tab" data-toggle="pill" href="#v-pills-reseller"
                           role="tab" aria-controls="v-pills-reseller" aria-selected="false">Reseller License</a>

                        <a className="nav-link" id="v-pills-webhosting-tab" data-toggle="pill"
                           href="#v-pills-webhosting" role="tab" aria-controls="v-pills-webhosting"
                           aria-selected="false">Web Hosting</a>

                        <a className="nav-link" id="v-pills-videocommercial-tab" data-toggle="pill"
                           href="#v-pills-videocommercial" role="tab" aria-controls="v-pills-videocommercial"
                           aria-selected="false">Video Commercial</a>

                        <a className="nav-link" id="v-pills-videopromo-tab" data-toggle="pill"
                           href="#v-pills-videopromo" role="tab" aria-controls="v-pills-videopromo"
                           aria-selected="false">Video Promos</a>

                        <a className="nav-link" id="v-pills-dfylead-tab" data-toggle="pill" href="#v-pills-dfylead"
                           role="tab" aria-controls="v-pills-dfylead" aria-selected="false">DFY Lead Magnets</a>

                        <a className="nav-link" id="v-pills-agency-tab" data-toggle="pill" href="#v-pills-agency"
                           role="tab" aria-controls="v-pills-agency" aria-selected="false">Agency Website</a>

                        <a className="nav-link" id="v-pills-social-tab" data-toggle="pill" href="#v-pills-social"
                           role="tab" aria-controls="v-pills-social" aria-selected="false">Social Media Assets</a>
                        <a className="nav-link" id="v-pills-dfyarticles-tab" data-toggle="pill"
                           href="#v-pills-dfyarticles" role="tab" aria-controls="v-pills-dfyarticles"
                           aria-selected="false">DFY Articles</a>
                     </>
                     : ''}

                  {membership.includes("afftrack") || membership.includes("bundle") ?
                     <>
                        <a className="nav-link" id="v-pills-affiliate-tab" data-toggle="pill"
                           href="#v-pills-affiliate" role="tab" aria-controls="v-pills-afftrack_affiliate"
                           aria-selected="false">Afftrack Affiliate Approval</a>
                        <a className="nav-link" id="v-pills-youtube-tab" data-toggle="pill"
                           href="#v-pills-youtube" role="tab" aria-controls="v-pills-youtube"
                           aria-selected="false">YouTube Keyword Research</a>
                     </> : ''}

                  {membership.includes("professional") || membership.includes("bundle") ?
                     <>
                        <a className="nav-link" id="v-pills-intro-outro-tab" data-toggle="pill"
                           href="#v-pills-intro-outro" role="tab" aria-controls="v-pills-intro-outro"
                           aria-selected="false">Intro/Outro Creater</a>
                        {/* hide it for now dont remove */}

                        {/* <a className="nav-link" id="v-pills-Offer-Finder-tab" data-toggle="pill"
                           href="#v-pills-Offer-Finder" role="tab" aria-controls="v-pills-Offer-Finder"
                           aria-selected="false">Offer Finder</a> */}
                     </> :
                     ''}
                  {membership.includes("unlimited") || membership.includes("bundle") ?
                     <a className="nav-link" id="v-pills-Social-Traffic-Generator-tab" data-toggle="pill"
                        href="#v-pills-Social-Traffic-Generator" role="tab" aria-controls="v-pills-Social-Traffic-Generator"
                        aria-selected="false">Social Traffic Generator</a> : ''}

               </div>
            </div>
         </div>
      </>
   )
}

export default UpgradesMenu;