import React, { useState } from 'react';
import { List3style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <List3style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview flex-column">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="head-box text-center">
                                    <h1 className='myStyle'>Feature list</h1>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="feature-wrapper">
                                    {props.templateText[0] ?
                                        <div className="feature_list-1">
                                            <div className=" list-card">
                                                <span className="line-1"></span>
                                                <div className="list-1">
                                                    <svg viewBox="-23 -2 23 5">
                                                        <path d="M 0 1 A 1 1 0 0 0 -1 4 L -23 3 L -23 -2 L 0 -2" fill="#FFCA28" />
                                                    </svg>
                                                    <div className="text-wrappers">
                                                        <TextEditor
                                                            layer={layer}
                                                            setLayer={setLayer}
                                                            layerStatus={props.templateText[0].is_selected}
                                                            textStatus={props.templateText[0].text.is_selected}
                                                            layerId={props.templateText[0].id}
                                                            text={props.templateText[0].text.text}
                                                            style={props.templateText[0].text.style}
                                                            templateNumber={props.templateNumber}
                                                            setTextLength={props.setTextLength}
                                                            setMaxLimit={props.setMaxLimit}
                                                            blockType={
                                                                {
                                                                    tag: 'p',
                                                                }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                    {props.templateText[1] ?
                                        <div className="feature_list-2">
                                            <div className=" list-card">
                                                <span className="line-2"></span>
                                                <div className="list-2">
                                                    <svg viewBox="-23 -2 23 5">
                                                        <path d="M 0 1 A 1 1 0 0 0 -1 4 L -23 3 L -23 -2 L 0 -2" fill="#FFCA28" />
                                                    </svg>
                                                    <div className="text-wrappers">
                                                        <TextEditor
                                                            layer={layer}
                                                            setLayer={setLayer}
                                                            layerStatus={props.templateText[1].is_selected}
                                                            textStatus={props.templateText[1].text.is_selected}
                                                            layerId={props.templateText[1].id}
                                                            text={props.templateText[1].text.text}
                                                            templateNumber={props.templateNumber}
                                                            style={props.templateText[1].text.style}
                                                            setTextLength={props.setTextLength}
                                                            setMaxLimit={props.setMaxLimit}
                                                            blockType={
                                                                {
                                                                    tag: 'p',
                                                                }

                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="feature-wrapper-2">
                                    {props.templateText[2] ?
                                        <div className="feature_list-1">
                                            <div className=" list-card">
                                                <span className="line-1"></span>
                                                <div className="list-1">
                                                    <svg viewBox="-23 -2 23 5">
                                                        <path d="M 0 1 A 1 1 0 0 0 -1 4 L -23 3 L -23 -2 L 0 -2" fill="#FFCA28" />
                                                    </svg>
                                                    <div className="text-wrappers">
                                                        <TextEditor
                                                            layer={layer}
                                                            setLayer={setLayer}
                                                            layerStatus={props.templateText[2].is_selected}
                                                            textStatus={props.templateText[2].text.is_selected}
                                                            layerId={props.templateText[2].id}
                                                            text={props.templateText[2].text.text}
                                                            style={props.templateText[2].text.style}
                                                            templateNumber={props.templateNumber}
                                                            setTextLength={props.setTextLength}
                                                            setMaxLimit={props.setMaxLimit}
                                                            blockType={
                                                                {
                                                                    tag: 'p',
                                                                }

                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                    {props.templateText[3] ?
                                        <div className="feature_list-2">
                                            <div className=" list-card">
                                                <span className="line-2"></span>
                                                <div className="list-2">
                                                    <svg viewBox="-23 -2 23 5">
                                                        <path d="M 0 1 A 1 1 0 0 0 -1 4 L -23 3 L -23 -2 L 0 -2" fill="#FFCA28" />
                                                    </svg>
                                                    <div className="text-wrappers">
                                                        <TextEditor
                                                            layer={layer}
                                                            setLayer={setLayer}
                                                            layerStatus={props.templateText[3].is_selected}
                                                            textStatus={props.templateText[3].text.is_selected}
                                                            layerId={props.templateText[3].id}
                                                            text={props.templateText[3].text.text}
                                                            style={props.templateText[3].text.style}
                                                            templateNumber={props.templateNumber}
                                                            setTextLength={props.setTextLength}
                                                            setMaxLimit={props.setMaxLimit}
                                                            blockType={
                                                                {
                                                                    tag: 'p',
                                                                }

                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </List3style >

    );

};
export default Template;