import styled from "styled-components";
export const Description3style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }

  .video-player{
    width:220px!important;
    max-height:100%;
  }
  
  .video-player video{
    object-fit:unset;
  }

.video-player{
    width:220px!important;
    max-height:100%!important;
}

.product-wrappers {
    height: 225px;
    position: absolute;
    top: 58px;
    right: 16px;
 }
 
 .product-wrappers img{
   max-height:100%
 }

 .head-box{
    width:265px;
    position: absolute;
    top: 60px;
    left:36px;
 }
 .head-box{
    position: absolute;
    top: 60px;
    left:36px;
 }
 .head-box{
    position: absolute;
    top: 60px;
    left:36px;
 }
 .head-box h1{
    width:100%;
    height:auto;
 }


.text-wrappers{
   width:265px;
   position: absolute;
   top: 150px;
   left:36px;
}

.text-wrappers p{
  width:100%;
  height:auto;
}


@media (max-width: 1199.98px) { 
    .video-player{
        width:200px!important;
        max-height:100%;
     }
   
   .product-wrappers {
        height: 145px;
        position: absolute;
        top: 58px;
        right: 12px;
    } 
  
  .text-wrappers{
    width:265px;
    left:20px;
  }

  .head-box{
    left:20px;
 }




}

`;