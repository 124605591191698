import styled from "styled-components";
export const Dedicated4style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }
  

.product-wrappers {
  height: 160px;
  position: absolute;
  top: 80px;
  left: 69px;
  z-index: 1;
}
.product-wrappers-2 {
  height: 138px;
    position: absolute;
    top: 100px;
    left: 62px;
    z-index: 1;
}

 .product-wrappers img{
   max-height:100%
 }

.head-box {
    width:100%;
    position: absolute;
    top: 53px;
}

.head-box h1 {
    font-size: 30px;
}

.cricle{
    width: 280px;
    height: 280px;
    background: #FCAB10;
    border-radius: 50%;
    position: absolute;
    top: 25px;
    right: 7px;
}

.text-wrappers {
  width:100%;
  position: absolute;
  top: 138px;
}

.video-player{
  width:200px!important;
  max-height:100%;
}



@media (max-width: 1199.98px) { 
  
    .text-wrappers {
      top: 135px;
    }
    .product-wrappers {
        height: 190px;
    }

  .product-wrappers {
    height: 145px;
    position: absolute;
    top: 69px;
    left: 56px;
    z-index: 1;
  }

  .video-player {
    width: 155px!important;
    max-height: 100%;
  }

  .product-wrappers-2 {
    height: 109px;
    position: absolute;
    top: 87px;
    left: 63px;
    z-index: 1;
  }

 .cricle {
    width: 230px;
    height: 230px;
    background: #FCAB10;
    border-radius: 50%;
    position: absolute;
    top: 25px;
    right: 7px;

  }
    
    
}


`;