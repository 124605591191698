import React, { useState } from "react"
import { Props_Cons3style } from './style'
import MediaEditor from "../../../Preview/MediaEditor";
import TextEditor from "../../../Preview/TextEditor";
import TemplateWatermark from "../../../Preview/TemplateWatermark";
import TemplateLogo from "../../../Preview/TemplateLogo";

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    let num
    return (
        <Props_Cons3style>
            <div className={`template-wrapper   ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    // data={Pros_and_Cons3}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview">
                    <div className="container">
                        <div className="row">

                            <div className="col-sm-6">
                                <div className=" card-wrapper">
                                    <div className="circle" style={{ backgroundColor: "#00a002" }}>
                                        <span>PRO'S</span>
                                    </div>
                                    <div className="polyLine" style={{ background: "#00a002" }}> </div>
                                    <div className="content-wrappers">

                                        {props.templateText.map((curElem, index) => {

                                            if (curElem.text.textType === "pros") {
                                                return (
                                                    <div className="text-wrappers mb-2" key={curElem.id}>
                                                        <span className='green-box' style={{ backgroundColor: "#00a002" }}>{index + 1}</span>
                                                        <div className="text-content">
                                                            <TextEditor
                                                                layer={layer}
                                                                setLayer={setLayer}
                                                                layerStatus={curElem.is_selected}
                                                                textStatus={curElem.text.is_selected}
                                                                layerId={curElem.id}
                                                                text={curElem.text.text}
                                                                style={curElem.text.style}
                                                                setTextLength={props.setTextLength}
                                                                setMaxLimit={props.setMaxLimit}
                                                                templateNumber={props.templateNumber}
                                                                blockType={
                                                                    { tag: 'p', }
                                                                }

                                                            />
                                                        </div>
                                                    </div>

                                                )
                                            }
                                        })}

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card-wrapper-2">
                                    <div className="circle-2" style={{ backgroundColor: "#bf0100" }}>
                                        <span>CON'S</span>
                                    </div>
                                    <div className="polyLine-2" style={{ background: "#bf0100" }}> </div>
                                    <div className="content-wrappers-2">

                                        {props.templateText.map((curElem, index) => {
                                            if (curElem.text.textType === "pros") {
                                                num = index
                                            }
                                            if (curElem.text.textType === "cons") {

                                                return (
                                                    <div className="text-wrappers-2 mb-2" key={curElem.id}>
                                                        <span className='green-box-2' style={{ backgroundColor: "#bf0100" }}>{index - num}</span>
                                                        <div className="text-content">
                                                            <TextEditor
                                                                layer={layer}
                                                                setLayer={setLayer}
                                                                layerStatus={curElem.is_selected}
                                                                textStatus={curElem.text.is_selected}
                                                                layerId={curElem.id}
                                                                text={curElem.text.text}
                                                                style={curElem.text.style}
                                                                setTextLength={props.setTextLength}
                                                                setMaxLimit={props.setMaxLimit}
                                                                templateNumber={props.templateNumber}
                                                                blockType={
                                                                    { tag: 'p', }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Props_Cons3style >

    );
};
export default Template;