import './App.css';
import './responsive.css';
import "./WhiteLabel.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { PrivateRoute } from "./components/PrivateRoute";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Profile from "./components/user/Profile";
import Projects from "./components/project/Projects";
import Privacy from "./components/user/Privacy";
import AccountManagement from "./components/user/AccountManagement";
import Upgrades from "./components/user/Upgrades";
import HelpSupport from "./components/support/HelpSupport";
import Training from "./components/support/Training";
import TrainingArticles from "./components/support/TrainingArticles";
import MasterLogin from "./components/MasterLogin";
import Integration from "./components/integration/Integration";
import CeeateVideo from './components/video/CreateVideo';
import Editor from './components/editor/Editor';
import { useSelector, useDispatch } from 'react-redux';
import { removeAlert } from './actions/alert';
import { useEffect, useState } from 'react';
import ReelMerge from './components/project/ReelMerge';
import { onFetchIsWhitelabel } from './actions/WhiteLabelActions';
import WhiteLabelTabs from './components/White-Label/WhiteLabelTabs';

function App() {
    const alert = useSelector(state => state.alert)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [membership, setMembership] = useState([]);
    const rebrand = useSelector((state) => state.rebrand?.data);
    console.log(useSelector((state) => state.rebrand))
    const [loader, setLoader] = useState({
        fetch: true,
    });

    // const fetchUser = () => {
    //   dispatch(loadUser())
    // }

    const fetchWhiteLabel = () => {
        setLoader({
            ...loader,
            fetch: true,
        });
        let obj = { domain: window.location.host };
        dispatch(onFetchIsWhitelabel(obj, loader, setLoader));
    };

    useEffect(() => {
        if (rebrand?.reseller?.color?.first && rebrand?.reseller?.color?.second) {
            document.documentElement.style.setProperty(
                "--primary",
                rebrand?.reseller?.color?.first
            );
            document.documentElement.style.setProperty(
                "--secondary",
                rebrand.reseller?.color?.second
            );
        }
    }, [rebrand]);

    useEffect(() => {
        fetchWhiteLabel();

    }, []);


    useEffect(() => {
        if (auth.user !== false) {
            if (auth.user.membership !== '' && auth.user.membership !== null) {
                setMembership(auth.user.membership.split('__'))
            }
        }
    }, [auth])
    useEffect(() => {
        if (alert !== undefined) {
            if (alert.message !== "") {
                setTimeout(() => {
                    console.log("Remove ALert")
                    dispatch(removeAlert())
                }, 5000);
            }
        }
    }, [alert.message])



    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forget-password" component={ForgetPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/master-login" component={MasterLogin} />
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/projects" component={Projects} />
                    <PrivateRoute exact path="/privacy" component={Privacy} />
                    <PrivateRoute exact path="/account-management" component={AccountManagement} />
                    <PrivateRoute exact path="/upgrades" component={Upgrades} />
                    <PrivateRoute exact path="/help-and-support" component={HelpSupport} />
                    <PrivateRoute exact path="/training" component={Training} />
                    <PrivateRoute exact path="/support-article" component={TrainingArticles} />
                    <PrivateRoute exact path="/integration" component={Integration} />
                    <PrivateRoute exact path="/white-label" component={WhiteLabelTabs} />
                    {membership.includes("unlimited") || membership.includes("unlimitedlite") || membership.includes("bundle") ?
                        < PrivateRoute exact path="/reel-merge" component={ReelMerge} /> : ''
                    }
                    <PrivateRoute exact path="/create-video" component={CeeateVideo} />
                    <PrivateRoute exact path="/editor" component={Editor} />
                    <Route path="*" component={Login} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
