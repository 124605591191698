import styled from "styled-components";

export const Testimonial6style = styled.div`


.row{
  margin-top:170px;
}

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }
  
  .template-wrapper img{
    max-height:100%;
  }
  .image-wrapper img{
    width:100%;
    height:100%;
  }
  .employee-name{
    margin-top:9px;
  }
  .employee-para{
    margin-top:18px;
  }
  .template-preview {
    position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }

  .texts-wrappers{
    width: 194px;
    height: 140px;
    position: absolute;
    top: 21px;
    left: 89px;
    border-left: 7px solid #000;
    border-right: 7px solid #000;
  }
  .texts-wrappers-2{
    width: 194px;
    height: 140px;
    position: absolute;
    top: 21px;
    left: 89px;
    border-left: 7px solid #000;
    border-right: 7px solid #000;
  }
  .slide-2{
    position: absolute;
  }
   .slide-3 {
    position: absolute;
    left: -1px;
    top: 174px;
  }
   .slide-4{
    position: absolute;
    left: 13px;
    top: 174px;
  }
  .quote{
    position: absolute;
    top: -12px;
    left: 16px;
    transform: rotate(183deg);
  }
  .quote-2{
    position: absolute;
    bottom: -10px;
    right: 14px;
  }
  .line-1{
    position: 0absolute;
    top: 0;
    background: #000;
    width: 13px;
    height: 4px;
  }
  .line-2{
    position: absolute;
    top: 50%;
    left: -22px;
    background: #000;
    width: 16px;
    height: 4px;
  }
  .line-3{
    position: absolute;
    bottom: 0;
    background: #000;
    width: 13px;
    height: 4px;
  }
  .line-4{
    position: absolute;
    top: 0;
    right:0;
    background: #000;
    width: 13px;
    height: 4px;
  }
  .line-5{
    position: absolute;
    top: 50%;
    right:-20px;
    background: #000;
    width: 13px;
    height: 4px;
  }
  .line-6{
    position: absolute;
    bottom: 0;
    right:0;
    background: #000;
    width: 13px;
    height: 4px;
  }
  .image-wrapper{
    position: absolute;
    top: 56px;
    left: -6px;
    width: 80px;
    height: 80px;
    border: 2px solid #000;
  }
  
@media (max-width: 1199.98px) { 

 
 }

`;