import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import TrainingTitles from "./TrainingTitles";
import TrainingContents from "./TrainingContents";
import Footer from "../Footer";
import Title from "../Title";

const Training = ({ location }) => {
    return (
        <>
            <Title title="Training" />
            <Navbar />

            <section className="traning-sec">
                <div className="container">
                    <div className="row">

                        <TrainingTitles location={location} />
                        <TrainingContents location={location} />

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Training;