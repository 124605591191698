import styled from "styled-components";
export const  Bonusdedicate6style = styled.div`

.template-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  text-align:center;
}

.template-wrapper img{
  max-height:100%;
}

.template-preview {
  position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.media-bg{
    position: absolute;
    top: 50px;
    left: 9px;
    width: 290px;
    border-radius: 50%;
    height: 290px;
    background:#7786A8;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.media-bg-2{
  position: absolute;
  top: 63px;
  left: 21px;
  width: 265px;
  border-radius: 50%;
  height: 265px;
  background: #1A2F4A;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.product-wrappers {
  height: 142px;
  position: absolute;
  top: 120px;
  left: 47px;
}
 .product-wrappers-2 {
    height: 175px;
    position: absolute;
    top: 103px;
    left: 59px
}

.circle-1{
  background: #ffffff3d;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 46%;
}

.circle-2{
  background: #fff;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 7px;
}

  .circle-3{
    background:#C94848;
    width:80px;
    height:80px;
    border-radius:50%;
    position:absolute;
    top:8px;
    left:8px;

  }
  .price-value{
    margin-top:25px;
  }

.product-wrappers img{
  height:100%
}

.video-player{
  width:191px!important;
  max-height:100%;
}

.video-player video{
  object-fit:unset;
}

.text-wrappers-1 {
   
    margin-top: 53px;
    margin-left: 38px;
    height: auto;
    width: 77%;
    height: auto;
}

.text-wrappers-1 h1{
   width:100%;
   height:100%;
   overflow:hidden;
   margin-bottom:0px;
}

.text-area{
  position: absolute;
  top: 50px
}
 
.text-wrappers-2 {
 
  margin-top: 25px;
  margin-left: 38px;
  height: auto;
  width: 77%;
  height: auto;
}
.text-wrappers-2 p{
  width:100%;
  height:auto;
  margin-bottom:0px;
  overflow:hidden;
}


@media (max-width: 1199.98px) { 

  .media-bg {
    position: absolute;
    top: 50px;
    left: 9px;
    width: 250px;
    border-radius: 50%;
    height: 250px;
    background:#7786A8;
  }
  .media-bg-2 {
    position: absolute;
    top: 59px;
    left: 18px;
    width: 232px;
    border-radius: 50%;
    height: 232px;
    background:#1A2F4A;
  }
  .product-wrappers {
    height: 170px;
    position: absolute;
    top: 90px;
    left: 48px;
  }

  .video-player {
    width: 173px!important;
    max-height: 100%;
   }
 
   .product-wrappers-2 {
    height: 139px;
    position: absolute;
    top: 97px;
    left: 55px;
  }

  .product-wrappers {
    height: 117px;
    position: absolute;
    top: 116px;
    left: 47px;
  }
  .text-wrappers-1 {
    margin-top: 53px;
    margin-left: 9px;
    width: 90%;
    height: auto;
}

}

`;