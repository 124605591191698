import React, { useState } from "react"
import { Props_Cons6style } from './style'
import MediaEditor from "../../../Preview/MediaEditor";
import TextEditor from "../../../Preview/TextEditor";
import TemplateLogo from "../../../Preview/TemplateLogo";
import TemplateWatermark from "../../../Preview/TemplateWatermark";
import prosImg from "../../../../../images/pros6.png";
import consImg from "../../../../../images/cons6.png";


const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });

    const myStyle = {
        marginTop: '6px',
        fontSize: '25px',
        fontFamily: 'Gilroy',
        textAlign: 'center',

    }

    return (
        <Props_Cons6style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview ">
                    <div className="container">
                        <div className="row m-0">
                            <div className=" col-sm-12 p-0">
                                <div className="row d-flex mt-3">
                                    <div className=" col-sm-6 p-0">
                                        <div className="head d-flex justify-content-center align-items-center">
                                            <div className="pros-img ">
                                                <img src={prosImg} alt="" style={{ width: "62px" ,marginLeft:"3px"}} />
                                            </div>
                                            <span style={{fontSize:"22px",fontWeight:"700"}}>PRO'S</span>
                                        </div>
                                    </div>
                                    <div className=" col-sm-6 p-0">
                                        <div className="head d-flex justify-content-center align-items-center">
                                            <span style={{fontSize:"22px",fontWeight:"700"}}>CON'S</span>

                                            <div className="cons-img ">
                                                <img src={consImg} alt="" style={{ width: "62px",marginLeft:"3px" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* PROS */}
                                <div className="text-wrappers">
                                    <div className="row m-0">
                                        {props.templateText.map((curElem, index) => {
                                            if (curElem.text.textType === "pros") {
                                                return (
                                                    <div className="col-sm-12 mb-3 p-0" key={curElem.id}>
                                                        <div className="text-content">
                                                            <TextEditor
                                                                layer={layer}
                                                                setLayer={setLayer}
                                                                layerStatus={curElem.is_selected}
                                                                textStatus={curElem.text.is_selected}
                                                                layerId={curElem.id}
                                                                text={curElem.text.text}
                                                                style={curElem.text.style}
                                                                setTextLength={props.setTextLength}
                                                                setMaxLimit={props.setMaxLimit}
                                                                templateNumber={props.templateNumber}
                                                                blockType={
                                                                    {
                                                                        tag: 'p',
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                                {/* CONS */}
                                <div className="text-wrappers-2">
                                    <div className="row m-0">
                                        {props.templateText.map((curElem, index) => {
                                            if (curElem.text.textType === "cons") {
                                                return (
                                                    <div className=" col-sm-12 mb-3 p-0" key={curElem.id}>
                                                        <div className="text-content">
                                                            <TextEditor
                                                                layer={layer}
                                                                setLayer={setLayer}
                                                                layerStatus={curElem.is_selected}
                                                                textStatus={curElem.text.is_selected}
                                                                layerId={curElem.id}
                                                                setTextLength={props.setTextLength}
                                                                setMaxLimit={props.setMaxLimit}
                                                                text={curElem.text.text}
                                                                style={curElem.text.style}
                                                                templateNumber={props.templateNumber}
                                                                blockType={
                                                                    {
                                                                        tag: 'p',
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Props_Cons6style>

    );
};
export default Template;