import { produce } from "immer"

const initialState = {
    data: {
        isWhiteLabel: false
    },
    stripe: []
}

export const WhiteLabelData = (state = initialState, action) => {
    switch (action.type) {
        case "WHITELABEL_STATUS_CHANGE":
            return produce(state, (draft) => {
                draft.data.isWhiteLabel = action.payload
            })

        case 'ADD_STRIPE_ACCOUNTS':
            if (!action.payload) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state,
                    stripe: action.payload.stripe ? action.payload.stripe : [],
                }
            }

        case 'REMOVE_STRIPE_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}