import React, { useState } from 'react';
import { Testimonial4style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';
import shape1 from '../../../../../images/temp4_1_bg.png';
import shape2 from '../../../../../images/temp4_2_bg.png';
import shape3 from '../../../../../images/temp4_3_bg.png';
import shape4 from '../../../../../images/temp4_4_bg.png';
import quote from '../../../../../images/testi4_quote.png';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Testimonial4style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview">
                    <div className="container">
                        <div className="row m-0">
                            {props.templateText.map((curElem) => {
                                let image
                                if(curElem.id === 1){
                                    image = shape1
                                }else if(curElem.id === 2){
                                    image = shape2
                                }else if(curElem.id === 3){
                                    image = shape3
                                }else if (curElem.id === 4){
                                    image = shape4
                                }
                                return (
                                    // <div className="col-sm-3" key={curElem.id}>
                                    <div className="col-sm-3" key={curElem.id}>

                                        <div className="card card-1">
                                            <div className="image-wrapper">
                                                <div className="card-img-background">
                                                    
                                                    <img src={image} alt="" />
                                                </div>

                                                <div className={`${layer.image && curElem.is_selected ? 'image-bg-border' : ''} card-img-box`}>
                                                    <MediaEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        mediaType="pImage"
                                                        layerId={curElem.id}
                                                        data={curElem.image}
                                                        type={curElem.image.includes("mp4") ? 'video' : 'image'}
                                                        setSelectImageLayer={props.setSelectImageLayer}
                                                    />
                                                </div>
                                            </div>
                                            <div className="card-body text-head-wrapper">
                                                <div className="card-title">
                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.name.is_selected}
                                                        layerId={curElem.id}
                                                        textId={curElem.name.id}
                                                        text={curElem.name.text}
                                                        style={curElem.name.style}
                                                        type={props.templateType}
                                                        templateNumber={props.templateNumber}
                                                        setTextLength={props.setTextLength}
                                                        setMaxLimit={props.setMaxLimit}
                                                        blockType={
                                                            {
                                                                tag: 'h1',
                                                            }

                                                        }
                                                    />
                                                </div>
                                                <div className="card-text">

                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.designation.is_selected}
                                                        layerId={curElem.id}
                                                        textId={curElem.designation.id}
                                                        text={curElem.designation.text}
                                                        style={curElem.designation.style}
                                                        type={props.templateType}
                                                        setTextLength={props.setTextLength}
                                                        setMaxLimit={props.setMaxLimit}
                                                        templateNumber={props.templateNumber}
                                                        blockType={
                                                            {
                                                                tag: 'span',
                                                            }
                                                        }

                                                    />
                                                </div>

                                                <div className="quotation">
                                                    <img src={quote} alt="" />
                                                </div>
                                            </div>
                                            <div className="card-body texts-wrapper">
                                                <TextEditor
                                                    layer={layer}
                                                    setLayer={setLayer}
                                                    layerStatus={curElem.is_selected}
                                                    textStatus={curElem.text.is_selected}
                                                    layerId={curElem.id}
                                                    textId={curElem.text.id}
                                                    text={curElem.text.text}
                                                    style={curElem.text.style}
                                                    templateNumber={props.templateNumber}
                                                    type={props.templateType}
                                                    setTextLength={props.setTextLength}
                                                    setMaxLimit={props.setMaxLimit}
                                                    blockType={
                                                        {
                                                            tag: 'p',
                                                        }

                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>


                                )
                            })}


                        </div>
                    </div>
                </div>
            </div>

        </Testimonial4style >

    );

};
export default Template;