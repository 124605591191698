import styled from "styled-components";
export const Description2style = styled.div`

.template-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview{
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }

  .video-player video{
    object-fit:unset;
  }

.video-player{
    width:170px!important;
    max-height:100%!important;
}

.product-wrappers {
  height: 130px;
  position: absolute;
  top: -71px;
  left: 34px;
 }
 
 .product-wrappers img{
   max-height:100%
 }

.head-box {
  margin-top: 25px;
  width: 74%;
   margin-left: 92px
}
  
.head-box h1 {
    text-transform: uppercase;
    color: #fff;
    margin-bottom:0;
}
.text-wrappers{
   margin-top: 45px;
   width: 74%;
   margin-left: 92px
}
.text-wrappers p{
  width:100%;
}

.background{
  width: 412px;
  background-image: linear-gradient(to right,#ACDBFF,#C7E7FF);
  clip-path: circle(75.9% at 79% 50%);
  height: 360px;
  position: absolute;
  top:0;
}

.background-2{
  background-image: linear-gradient(to right,#ACDBFF,#C7E7FF);
  width: 360px;
  height: 140px;
  position: absolute;
  top: 219px;
  left: 0;
 }

@media (max-width: 1199.98px) { 
    .text-wrappers {
      margin-left: 82px;
    }
    
    .head-box {
      margin-left: 82px;
    }

    .background {
      width: 353px;
      background-image: linear-gradient(to right,#ACDBFF,#C7E7FF);
      clip-path: circle(75.9% at 79% 50%);
      height: 307px;
      position: absolute;
      top: 0;
   }
    .product-wrappers {
      height: 130px;
      position: absolute;
      top: -80px;
      left: 22px
    }
    .video-player{
        width:150px!important;
        max-height:100%;
     }
}

`;