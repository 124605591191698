import styled from "styled-components";
export const Testimonial1style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }
  
  .template-wrapper img{
    max-height:100%;
  }
  
  .template-preview {
    position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }
  
.card img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1);
}

.card {
    border-radius: 0;
    width: 9.2rem;
    height: 21.4rem;
    overflow: hidden;
    border: none;
    position: absolute;
    top: 7px;
    left: 3px;
}

.card-title {
    background: #000;
    width: 140px;
    height:60px;
    position: relative;
    top: -35px;
    left: -17px;
    overflow:hidden;
}
.card-title span {
    margin-left: 2px;
    width: 120px;
    padding: 0;
}

.content-box {
    position: relative;
    top: -18px;
}
.temp-img{
    height:120px;
    text-align:center;
}
.temp-img{
    max-height:100%!important;

}
.video-player{
  }
  
.video-player video{
   height:115px!important;
  
  }

.quote {
    position: absolute;
    top: -20px;
    left: -13px;
}

.quote img {
    width:28px;
}

.card-text{
    width: 138px;
    height: 140px;
    margin-left: -12px;
    margin-top: -8px;
}
.card-text p{
    max-width: 100%;
    max-height:100%;
    overflow:hidden!important;
    padding:0 4px;
    margin-bottom:0;
}
h1{
 margin-bottom:0;
}

@media (max-width: 1199.98px) { 
    .card {
        width: 7rem !important;
        height:14rem;
        top:52px;
    }
     
    .card-title {
        width:110px;
        height: 33px;
        position: relative;
        top: -29px;
        left: -8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

   .card-body{
       padding: 1rem 0!important;
    }
     
    .card-text{
        width: 108px;
    }
   
    .content-box {
        position: relative;
        top: -10px;
    }

    .card p {
        font-weight: 600;
    }

    .quote {
        top: -23px;
        left: -7px;
    }

    .quote img {
        width: 41px;
    }

 
 }
 


`;