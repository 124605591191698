import styled from "styled-components";
import subjectivityBold from "../../../StaticMediaFiles/fonts/subjectivity.serif-extrabold.otf"
import subjectivitymedium from "../../../StaticMediaFiles/fonts/subjectivity.serif-mediumslanted.otf"
import subthin from "../../../StaticMediaFiles/fonts/subjectivity.thin.otf"
import Clarendon from "../../../StaticMediaFiles/fonts/Clarendon-Regular.ttf"

export const Intro2style = styled.div`

  .template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img {
    max-height: 100%;
   
  }
  
  .template-preview {
     position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }

  .text-wrappers {
    position: absolute;
    top: 63px;
    left: 12px;
    width: 100%;
    height: auto;
  }

  .text-wrappers h1 {
    width:100%;
    height:auto;
  }

.text-wrappers-2 {
  position: absolute;
  top: 140px;
  left: 12px;
  width: 100%;
  height: auto;
}
.text-wrappers-2 p {
  width:100%;
  height:auto;
}

.product_wrapper {
    position: absolute;
    top: 59px;
    right: 27px;
    height: 169px;
}

.product_wrapper img {
 max-height:100%;
}

.video-player{
    width:220px!important;
    max-height:100%;
}

.video-player video{
    object-fit:unset;
}


@media (max-width: 1199.98px) { 
    .text-wrappers {
        left: 10px!important;
        top:35px;
      }
      
      .text-wrappers-2 {
          left:10px!important;
          top:104px;
      }
    
    .product_wrapper {
      position: absolute;
      top: 98px;
      right: 21px;
      height:140px;
    }
    .video-player{
        width:180px!important;
        max-height:100%;
    }
    

}

`;