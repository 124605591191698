import styled from "styled-components";
export const Bonusdedicate2style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }
  .video-player{
    width:188px!important;
    max-height:100%;
  }
  
  .video-player video{
    object-fit:unset;
  }

.product-wrappers {
    height: 148px;
    position: absolute;
    top: 120px;
    left: 82px;
    z-index: 1;
  }

  .price-tag{
    width: 120px;
    position: absolute;
    top: -7px;
    right: 47px;
  }

  .product-wrappers img{
    max-height:100%
  }

.head-box {
    width:100%;
    position: absolute;
    top: 70px;
    margin-left: 12px;

}

.product-intro{
    position: absolute;
    left: 0;
    top: 150px;
}
.price-value{
  position: absolute;
  top: 49px;
  left: 32px;
}

.text-wrappers {
    width: 345px;
    margin-left: 12px;
}

.text-wrappers p {
  margin-left: 6px;
}


@media (max-width: 1199.98px) { 
  
    .text-wrappers {
        width: 250px;
        top: 120px;
    }
   
    .product-wrappers {
        height: 145px;
        position: absolute;
        top: 110px;
        left: 37px;
        z-index: 1;
    }
    .video-player{
        width:180px!important;
        max-height:100%;
     }

}


`;