import styled from "styled-components";
import testi_bg from "../../../StaticMediaFiles/image/testi_bg.png"
export const Testimonial2style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }
  
  .template-wrapper img{
    max-height:100%;
  }
  
  .template-preview {
    position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }

.card {
    border-radius: 0;
    width: 153px;

}

.card-1 {
    background: url(${testi_bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 317px;
    top: 6px;
    margin-left: -7px;
}

.card-2 {
    background: url(${testi_bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 317px;
    margin-left: -2px;
    top: 15px;
}

.card-3 {
    background: url(${testi_bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 317px;
    margin-left: 3px;
    top: 28px;
}

.card-4 {
    background: url(${testi_bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 317px;
    margin-left: 7px;
    top:40px;
}
.temp-img{
    height: 212px!important;
    text-align: center;
}
.temp-img img{
    max-height:100%;
}

 .video-player{
    width:220px!important;
    max-height:100%;
  }
  
  .video-player video{
    object-fit:unset;
  }

.quote {
    position: absolute;
    top: -8px;
    left: 5px;
}

.quote img {
    width:25px;
}

.text-wrappers {
    width:90%;
    height:28%;
    position: absolute;
    top: 9%;
    left: 5.4%;
}
.text-wrappers p{
    max-width:100%;
    max-height:100%;
    overflow:hidden;
}

.img-wrappers {
    position: absolute;
    bottom: 10px;
}

.text {
    position: absolute;
    bottom: 7px;
    left: -2px;
    right: 0;
    background: #fff;
    width: 158px;
    padding: 2px;
}
h1,p{
    margin-bottom:0;
}

.img-wrappers img {
    border: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.profileWrap .svg {
    width: 0;
    height: 0;
    position: absolute;
    transform: rotate(180deg);
}

.profileWrap {
    width: 256px;
}

.clipped {
    width: 57%;
    height: 174px;
    clip-path: url(#my-clip-path);
    margin-left: 3px;
}

.clipped-2 {
    width: 57%;
    height: 174px;
    clip-path: url(#my-clip-path);
    margin-left: 3px;
}

.clipped-3 {
    width: 57%;
    height: 174px;
    clip-path: url(#my-clip-path);
    margin-left: 3px;
}

.clipped-4 {
    width: 57%;
    height: 174px;
    clip-path: url(#my-clip-path);
    margin-left: 3px;
}

@media (max-width: 1199.98px) { 
     .card {
        width: 8rem !important;
    } 
    .card-1 {
        height: 255px;
        top:7px;
        margin-left: -4px;
    }
    
    .card-2 {
        height: 255px;
        margin-left: -6px;
        top:21px;
    }
    
    .card-3 {
        height: 255px;
        margin-left: -8px;
        top: 38px;
    }
    
    .card-4 {
        height: 255px;
        margin-left: -11px;
        top: 48px;
    }

    .clipped {
        width: 45%;
         height: 153px;
         margin-left: -10px;
      }
      
      .clipped-2 {
         width: 45%;
          height: 153px;
          margin-left: -10px;
      }
      
      .clipped-3 {
         width: 45%;
          height: 153px;
          margin-left: -10px;
      }
      
      .clipped-4 {
          width: 45%;
          height: 153px;
          margin-left: -10px;
      }
       .quote img {
        width: 25px;
    }
    .text {
        width: 119px;
    }
 }
 



`;