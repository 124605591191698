import styled from "styled-components";
export const Intro4style = styled.div`

.template-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  text-align:center;
}

.template-wrapper img{
  max-height:100%;
}

.template-preview {
  position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.media-border{
  position: absolute;
  top: 76px;
  left: 70px;
  border: 2px solid #fff;
  width: 235px;
  border-radius: 16px;
  height: 255px;
}
.media-bg{
  position: absolute;
  top: 62px;
  left: 40px;
  width: 244px;
  border-radius: 16px;
  height: 255px;
  background: #3598db;
}

.product-wrappers {
  height: 134px;
  position: absolute;
  top: 115px;
  right: 57px;
}
 .product-wrappers-2 {
  height: 150px;
  position: absolute;
  top: 115px;
  right: 74px;
}

.product-wrappers img{
  height:100%
}

.video-player{
  width:194px!important;
  max-height:100%;
}

.video-player video{
  object-fit:unset;
}

.text-wrappers-1 {
   
    margin-top: 53px;
    margin-left: 38px;
    height: auto;
    width: 77%;
    height: auto;
}

.text-wrappers-1 h1{
   width:100%;
   height:100%;
   overflow:hidden;
   margin-bottom:0px;
}
 
.text-wrappers-2 {
 
  margin-top: 53px;
  margin-left: 38px;
  height: auto;
  width: 77%;
  height: auto;
}
.text-wrappers-2 p{
  width:100%;
  height:auto;
  margin-bottom:0px;
  overflow:hidden;
}


@media (max-width: 1199.98px) { 

  .media-border {
    position: absolute;
    top: 76px;
    left: 70px;
    border: 2px solid #fff;
    width: 235px;
    border-radius: 16px;
    height: 223px;
 }
 .media-bg {
    position: absolute;
    top: 59px;
    left: 40px;
    width: 244px;
    border-radius: 16px;
    height: 225px;
    background: #3598db;
 }
  .video-player {
    width: 203px!important;
    max-height: 100%;
  }

   .product-wrappers {
    height: 140px;
    position: absolute;
    top: 125px;
    right: 7px;
  }

  .product-wrappers-2 {
    height: 150px;
    position: absolute;
    top: 98px;
    right: 26px;
}


}

`;