
const initialState = {
    data: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case 'ADD_VIDEO_DATA':
            return {
                ...state,
                data: action.payload
            }

        case 'ADD_NEW_SLIDE':
            let arr = [...state.data.slides]
            if (action.payload.length > 0) {
                arr.push(action.payload[0])
                arr.push(action.payload[1])
            }
            else {
                arr.push(action.payload)
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: arr
                }
            }
        case 'UPDATE_SLIDE_DURATION':

            let data = [...state.data.slides]
            const selectedTile = data.findIndex(({ is_selected }) => is_selected === true)
            data[selectedTile].duration = parseInt(action.payload)
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: data
                }
            }

        case 'UPDATE_STORY_TILE':
            let val = [...state.data.slides]
            const { slideIndex, scriptIndex } = action.payload
            const lastScriptIndex = state.data.slides[slideIndex].scriptText.findIndex(({ is_seclected }) => is_seclected === true)
            val[slideIndex].scriptText[lastScriptIndex].is_seclected = false
            val[slideIndex].scriptText[scriptIndex].is_seclected = true

            return {
                ...state,
                data: {
                    ...state.data,
                    slides: val
                }
            }

        case 'UPDATE_CAMPAIGN_NAME':
            return {
                ...state,
                data: {
                    ...state.data,
                    campaingName: action.payload
                }
            }

        case 'SET_NAME_LAYER':
            let slideArrNameLayer = [...state.data.slides]
            let textArrNameLayer = [...slideArrNameLayer[action.payload.selectedSlideIndex].text]
            textArrNameLayer[action.payload.selectedTextIndex].name.is_selected = true
            textArrNameLayer[action.payload.selectedTextIndex].designation.is_selected = false
            textArrNameLayer[action.payload.selectedTextIndex].text.is_selected = false

            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slideArrNameLayer
                }
            }

        case 'SET_TEXT_LAYER':
            let slideArrTextLayer = [...state.data.slides]
            let textArrTextLayer = [...slideArrTextLayer[action.payload.selectedSlideIndex].text]
            textArrTextLayer[action.payload.selectedTextIndex].name.is_selected = false
            textArrTextLayer[action.payload.selectedTextIndex].designation.is_selected = false
            textArrTextLayer[action.payload.selectedTextIndex].text.is_selected = true

            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slideArrTextLayer
                }
            }

        case 'SET_TEXT_EDITABLE':
            let slideArrEditText = [...state.data.slides]
            let textArrEditText = [...slideArrEditText[action.payload.selectedSlideIndex].text]
            textArrEditText.forEach((curElem) => {
                if (curElem.id === action.payload.layerId) {
                    curElem.is_selected = true
                }
                else {
                    curElem.is_selected = false
                }
            })
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slideArrEditText
                }
            }

        case 'SET_LAYER_EDITABLE_TESTIMONIAL':
            let slideArrTestiLayer = [...state.data.slides]
            let textArrTestiLayer = [...slideArrTestiLayer[action.payload.selectedSlideIndex].text]
            textArrTestiLayer.forEach((curElem) => {
                if (curElem.id === action.payload.layerId) {
                    curElem.is_selected = true
                }
                else {
                    curElem.is_selected = false
                }
            })
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slideArrTestiLayer
                }
            }

        case 'SET_INNER_TEXT_EDITABLE_FDEDICATED':
            let slideArrEditFInnerText = [...state.data.slides]
            let textArrEditFInnerText = [...slideArrEditFInnerText[action.payload.selectedSlideIndex].text]
            if (textArrEditFInnerText[action.payload.selectedTextIndex].heading !== undefined) {
                if (action.payload.textId === 1) {
                    textArrEditFInnerText[action.payload.selectedTextIndex].heading.is_selected = true
                    textArrEditFInnerText[action.payload.selectedTextIndex].text.is_selected = false
                }
                else {
                    textArrEditFInnerText[action.payload.selectedTextIndex].heading.is_selected = false
                    textArrEditFInnerText[action.payload.selectedTextIndex].text.is_selected = true
                }
            }
            if (textArrEditFInnerText[action.payload.selectedTextIndex].text) {
                if (action.payload.textId === 1) {
                    textArrEditFInnerText[action.payload.selectedTextIndex].text.is_selected = true
                    textArrEditFInnerText[action.payload.selectedTextIndex].text.is_selected = false
                }
                else {
                    textArrEditFInnerText[action.payload.selectedTextIndex].text.is_selected = false
                    textArrEditFInnerText[action.payload.selectedTextIndex].text.is_selected = true
                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slideArrEditFInnerText
                }
            }

        case 'SET_INNER_TEXT_EDITABLE_TESTIMONIAL':

            let slideArrEditTesInnerText = [...state.data.slides]
            let textArrEditTesInnerText = [...slideArrEditTesInnerText[action.payload.selectedSlideIndex].text]
            if (action.payload.textId === 1) {
                textArrEditTesInnerText[action.payload.layerId - 1].name.is_selected = true
                textArrEditTesInnerText[action.payload.layerId - 1].designation.is_selected = false
                textArrEditTesInnerText[action.payload.layerId - 1].text.is_selected = false
            }
            else if (action.payload.textId === 2) {
                textArrEditTesInnerText[action.payload.layerId - 1].name.is_selected = false
                textArrEditTesInnerText[action.payload.layerId - 1].designation.is_selected = true
                textArrEditTesInnerText[action.payload.layerId - 1].text.is_selected = false
            }
            else if (action.payload.textId === 3) {
                textArrEditTesInnerText[action.payload.layerId - 1].name.is_selected = false
                textArrEditTesInnerText[action.payload.layerId - 1].designation.is_selected = false
                textArrEditTesInnerText[action.payload.layerId - 1].text.is_selected = true
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slideArrEditTesInnerText
                }
            }

        case 'SET_INNER_TEXT_EDITABLE_BONUSES':

            let slideArrEditBonInnerText = [...state.data.slides]
            let textArrEditBonInnerText = [...slideArrEditBonInnerText[action.payload.selectedSlideIndex].text]
            if (action.payload.textId === 1) {
                if (textArrEditBonInnerText[action.payload.layerId - 1].heading) {
                    textArrEditBonInnerText[action.payload.layerId - 1].heading.is_selected = false
                }
                textArrEditBonInnerText[action.payload.layerId - 1].value.is_selected = true
                textArrEditBonInnerText[action.payload.layerId - 1].text.is_selected = false

            } else if (action.payload.textId === 2) {
                if (textArrEditBonInnerText[action.payload.layerId - 1].heading) {
                    textArrEditBonInnerText[action.payload.layerId - 1].heading.is_selected = false
                }
                textArrEditBonInnerText[action.payload.layerId - 1].value.is_selected = false
                textArrEditBonInnerText[action.payload.layerId - 1].text.is_selected = true
            }
            else {
                if (textArrEditBonInnerText[action.payload.layerId - 1].heading) {
                    textArrEditBonInnerText[action.payload.layerId - 1].heading.is_selected = true
                }
                textArrEditBonInnerText[action.payload.layerId - 1].value.is_selected = false
                textArrEditBonInnerText[action.payload.layerId - 1].text.is_selected = false
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slideArrEditBonInnerText
                }
            }


        case 'ADD_SCRAP_TEXT':
            return {
                ...state,
                text: action.payload
            }

        case 'ADD_NEW_TILES':
            let array = state.data.slides;
            array.push(action.payload);
            state.data.slides = array
            return {
                ...state,
            }

        case 'UPDATE_SELECTED_TILES':
            let index = state.data.slides.findIndex(({ is_selected }) => is_selected === true);
            state.data.slides[index].is_selected = false;
            state.data.slides[action.payload].is_selected = true;
            return {
                ...state
            }

        case 'UPDATE_SLIDES_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: action.payload
                }
            }

        case 'UPDATE_THUMBNAIL':
            state.data.poster = action.payload
            return {
                ...state
            }

        case 'UPDATE_STORY_STATUS':
            state.data.storyboard[action.payload].status = true;
            return {
                ...state
            }

        case 'UPDATE_SLIDE_TEXT':
            let slidesArrText = [...state.data.slides]
            let textArrText = [...slidesArrText[action.payload.selectedSlideIndex].text]
            textArrText[action.payload.selectedTextIndex].text.text = action.payload.text
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrText
                }
            }

        case 'UPDATE_SLIDE_INNER_TEXT':
            let slidesArrInnerText = [...state.data.slides]
            let textArrInnerText = [...slidesArrInnerText[action.payload.selectedSlideIndex].text]
            if (textArrInnerText[action.payload.selectedTextIndex].heading && action.payload.textId === 1) {
                textArrInnerText[action.payload.selectedTextIndex].heading.text = action.payload.val
            } else if (action.payload.type === "testimonials") {
                if (textArrInnerText[action.payload.selectedTextIndex].name && action.payload.textId === 1) {
                    textArrInnerText[action.payload.selectedTextIndex].name.text = action.payload.val
                }
                else if (textArrInnerText[action.payload.selectedTextIndex].designation && action.payload.textId === 2) {
                    textArrInnerText[action.payload.selectedTextIndex].designation.text = action.payload.val
                }
                else {
                    textArrInnerText[action.payload.selectedTextIndex].text.text = action.payload.val
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrInnerText[action.payload.selectedTextIndex].value && action.payload.textId === 1) {
                    textArrInnerText[action.payload.selectedTextIndex].value.text = action.payload.val
                }
                else if (textArrInnerText[action.payload.selectedTextIndex].text && action.payload.textId === 2) {
                    textArrInnerText[action.payload.selectedTextIndex].text.text = action.payload.val
                }
                else {
                    textArrInnerText[action.payload.selectedTextIndex].heading.text = action.payload.val
                }
            }
            else {
                textArrInnerText[action.payload.selectedTextIndex].text.text = action.payload.val
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrInnerText
                }
            }


        case 'DELETE_SLIDE':
            state.data.slides = action.payload
            return {
                ...state
            }

        case 'CHANGE_TEMPLATE_VARIANT':
            let slidesArrVarient = [...state.data.slides]
            slidesArrVarient[action.payload.selectedSlideIndex].categoryID = action.payload.templateData.category
            slidesArrVarient[action.payload.selectedSlideIndex].templateID = action.payload.templateData.id
            slidesArrVarient[action.payload.selectedSlideIndex].templateNumber = action.payload.templateData.template_number
            let type = "image"
            if (action.payload.templateData.preview_bgassets_url.includes(".mp4")) {
                type = "video"
            }
            slidesArrVarient[action.payload.selectedSlideIndex].backgroundData = {
                ...slidesArrVarient[action.payload.selectedSlideIndex].backgroundData,
                type: type,
                fileUrl: action.payload.templateData.preview_bgassets_url,
                poster: action.payload.templateData.preview_image_url
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrVarient
                }
            }

        case 'UPDATE_FONT_SIZE':

            let slidesArrFont = [...state.data.slides]
            let slidesArrTextFont = [...slidesArrFont[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (slidesArrTextFont[action.payload.selectedTextIndex].name.is_selected === true) {
                    slidesArrTextFont[action.payload.selectedTextIndex].name.style = {
                        ...slidesArrTextFont[action.payload.selectedTextIndex].name.style,
                        fontSize: parseInt(action.payload.fontSize)
                    }
                }
                if (slidesArrTextFont[action.payload.selectedTextIndex].designation.is_selected === true) {
                    slidesArrTextFont[action.payload.selectedTextIndex].designation.style = {
                        ...slidesArrTextFont[action.payload.selectedTextIndex].designation.style,
                        fontSize: parseInt(action.payload.fontSize)
                    }
                }
                if (slidesArrTextFont[action.payload.selectedTextIndex].text.is_selected === true) {
                    slidesArrTextFont[action.payload.selectedTextIndex].text.style = {
                        ...slidesArrTextFont[action.payload.selectedTextIndex].text.style,
                        fontSize: parseInt(action.payload.fontSize)
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (slidesArrTextFont[action.payload.selectedTextIndex].value.is_selected === true) {
                    slidesArrTextFont[action.payload.selectedTextIndex].value.style = {
                        ...slidesArrTextFont[action.payload.selectedTextIndex].value.style,
                        fontSize: parseInt(action.payload.fontSize)
                    }
                }
                if (slidesArrTextFont[action.payload.selectedTextIndex].heading) {
                    if (slidesArrTextFont[action.payload.selectedTextIndex].heading.is_selected === true) {
                        slidesArrTextFont[action.payload.selectedTextIndex].heading.style = {
                            ...slidesArrTextFont[action.payload.selectedTextIndex].heading.style,
                            fontSize: parseInt(action.payload.fontSize)
                        }
                    }
                }
                if (slidesArrTextFont[action.payload.selectedTextIndex].text.is_selected === true) {
                    slidesArrTextFont[action.payload.selectedTextIndex].text.style = {
                        ...slidesArrTextFont[action.payload.selectedTextIndex].text.style,
                        fontSize: parseInt(action.payload.fontSize)
                    }
                }
            }
            else {
                if (slidesArrTextFont[action.payload.selectedTextIndex].heading) {
                    if (slidesArrTextFont[action.payload.selectedTextIndex].heading.is_selected === true) {
                        slidesArrTextFont[action.payload.selectedTextIndex].heading.style = {
                            ...slidesArrTextFont[action.payload.selectedTextIndex].heading.style,
                            fontSize: parseInt(action.payload.fontSize)
                        }
                    }
                }

                if (slidesArrTextFont[action.payload.selectedTextIndex].text) {
                    if (slidesArrTextFont[action.payload.selectedTextIndex].text.is_selected === true) {
                        slidesArrTextFont[action.payload.selectedTextIndex].text.style = {
                            ...slidesArrTextFont[action.payload.selectedTextIndex].text.style,
                            fontSize: parseInt(action.payload.fontSize)
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrFont
                }
            }

        case 'UPDATE_FONT_FAMILY':
            let slidesArrfFamily = [...state.data.slides]
            let textArrfFamily = [...slidesArrfFamily[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrfFamily[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrfFamily[action.payload.selectedTextIndex].name.style = {
                        ...textArrfFamily[action.payload.selectedTextIndex].name.style,
                        fontFamily: action.payload.fontFamily
                    }
                }
                if (textArrfFamily[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrfFamily[action.payload.selectedTextIndex].designation.style = {
                        ...textArrfFamily[action.payload.selectedTextIndex].designation.style,
                        fontFamily: action.payload.fontFamily
                    }
                }
                if (textArrfFamily[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrfFamily[action.payload.selectedTextIndex].text.style = {
                        ...textArrfFamily[action.payload.selectedTextIndex].text.style,
                        fontFamily: action.payload.fontFamily
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrfFamily[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrfFamily[action.payload.selectedTextIndex].value.style = {
                        ...textArrfFamily[action.payload.selectedTextIndex].value.style,
                        fontFamily: action.payload.fontFamily
                    }
                }
                if (textArrfFamily[action.payload.selectedTextIndex].heading) {
                    if (textArrfFamily[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrfFamily[action.payload.selectedTextIndex].heading.style = {
                            ...textArrfFamily[action.payload.selectedTextIndex].heading.style,
                            fontFamily: action.payload.fontFamily
                        }
                    }
                }
                if (textArrfFamily[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrfFamily[action.payload.selectedTextIndex].text.style = {
                        ...textArrfFamily[action.payload.selectedTextIndex].text.style,
                        fontFamily: action.payload.fontFamily
                    }
                }
            }
            else {
                if (textArrfFamily[action.payload.selectedTextIndex].heading) {
                    if (textArrfFamily[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrfFamily[action.payload.selectedTextIndex].heading.style = {
                            ...textArrfFamily[action.payload.selectedTextIndex].heading.style,
                            fontFamily: action.payload.fontFamily
                        }
                    }
                }
                if (textArrfFamily[action.payload.selectedTextIndex].text) {
                    if (textArrfFamily[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrfFamily[action.payload.selectedTextIndex].text.style = {
                            ...textArrfFamily[action.payload.selectedTextIndex].text.style,
                            fontFamily: action.payload.fontFamily
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrfFamily
                }
            }


        case 'UPDATE_FONT_COLOR':
            let slidesArrcolor = [...state.data.slides]
            let textArrColor = [...slidesArrcolor[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrColor[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrColor[action.payload.selectedTextIndex].name.style = {
                        ...textArrColor[action.payload.selectedTextIndex].name.style,
                        fontColor: action.payload.color
                    }
                }
                if (textArrColor[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrColor[action.payload.selectedTextIndex].designation.style = {
                        ...textArrColor[action.payload.selectedTextIndex].designation.style,
                        fontColor: action.payload.color
                    }
                }
                if (textArrColor[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrColor[action.payload.selectedTextIndex].text.style = {
                        ...textArrColor[action.payload.selectedTextIndex].text.style,
                        fontColor: action.payload.color
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrColor[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrColor[action.payload.selectedTextIndex].value.style = {
                        ...textArrColor[action.payload.selectedTextIndex].value.style,
                        fontColor: action.payload.color
                    }
                }
                if (textArrColor[action.payload.selectedTextIndex].heading) {
                    if (textArrColor[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrColor[action.payload.selectedTextIndex].heading.style = {
                            ...textArrColor[action.payload.selectedTextIndex].heading.style,
                            fontColor: action.payload.color
                        }
                    }
                }
                if (textArrColor[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrColor[action.payload.selectedTextIndex].text.style = {
                        ...textArrColor[action.payload.selectedTextIndex].text.style,
                        fontColor: action.payload.color
                    }
                }
            }
            else {
                if (textArrColor[action.payload.selectedTextIndex].heading) {
                    if (textArrColor[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrColor[action.payload.selectedTextIndex].heading.style = {
                            ...textArrColor[action.payload.selectedTextIndex].heading.style,
                            fontColor: action.payload.color
                        }
                    }
                }
                if (textArrColor[action.payload.selectedTextIndex].text) {
                    if (textArrColor[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrColor[action.payload.selectedTextIndex].text.style = {
                            ...textArrColor[action.payload.selectedTextIndex].text.style,
                            fontColor: action.payload.color
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrcolor
                }
            }

        case 'UPDATE_FONT_BG_COLOR':
            let slidesArrBgcolor = [...state.data.slides]
            let textArrBgColor = [...slidesArrBgcolor[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrBgColor[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrBgColor[action.payload.selectedTextIndex].name.style = {
                        ...textArrBgColor[action.payload.selectedTextIndex].name.style,
                        bgColor: action.payload.color
                    }
                }
                if (textArrBgColor[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrBgColor[action.payload.selectedTextIndex].designation.style = {
                        ...textArrBgColor[action.payload.selectedTextIndex].designation.style,
                        bgColor: action.payload.color
                    }
                }
                if (textArrBgColor[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrBgColor[action.payload.selectedTextIndex].text.style = {
                        ...textArrBgColor[action.payload.selectedTextIndex].text.style,
                        bgColor: action.payload.color
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrBgColor[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrBgColor[action.payload.selectedTextIndex].value.style = {
                        ...textArrBgColor[action.payload.selectedTextIndex].value.style,
                        bgColor: action.payload.color
                    }
                }
                if (textArrBgColor[action.payload.selectedTextIndex].heading) {
                    if (textArrBgColor[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrBgColor[action.payload.selectedTextIndex].heading.style = {
                            ...textArrBgColor[action.payload.selectedTextIndex].heading.style,
                            bgColor: action.payload.color
                        }
                    }
                }
                if (textArrBgColor[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrBgColor[action.payload.selectedTextIndex].text.style = {
                        ...textArrBgColor[action.payload.selectedTextIndex].text.style,
                        bgColor: action.payload.color
                    }
                }
            }
            else {
                if (textArrBgColor[action.payload.selectedTextIndex].heading) {
                    if (textArrBgColor[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrBgColor[action.payload.selectedTextIndex].heading.style = {
                            ...textArrBgColor[action.payload.selectedTextIndex].heading.style,
                            bgColor: action.payload.color
                        }
                    }
                }
                if (textArrBgColor[action.payload.selectedTextIndex].text) {
                    if (textArrBgColor[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrBgColor[action.payload.selectedTextIndex].text.style = {
                            ...textArrBgColor[action.payload.selectedTextIndex].text.style,
                            bgColor: action.payload.color
                        }
                    }
                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrBgcolor
                }
            }

        case 'UPDATE_TEXT_LINE_HEIGHT':
            let slidesArrfLineHeight = [...state.data.slides]
            let textArrfLineHeight = [...slidesArrfLineHeight[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrfLineHeight[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrfLineHeight[action.payload.selectedTextIndex].name.style = {
                        ...textArrfLineHeight[action.payload.selectedTextIndex].name.style,
                        lineHeight: parseFloat(action.payload.value)
                    }
                }
                if (textArrfLineHeight[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrfLineHeight[action.payload.selectedTextIndex].designation.style = {
                        ...textArrfLineHeight[action.payload.selectedTextIndex].designation.style,
                        lineHeight: parseFloat(action.payload.value)
                    }
                }
                if (textArrfLineHeight[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrfLineHeight[action.payload.selectedTextIndex].text.style = {
                        ...textArrfLineHeight[action.payload.selectedTextIndex].text.style,
                        lineHeight: parseFloat(action.payload.value)
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrfLineHeight[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrfLineHeight[action.payload.selectedTextIndex].value.style = {
                        ...textArrfLineHeight[action.payload.selectedTextIndex].value.style,
                        lineHeight: parseFloat(action.payload.value)
                    }
                }
                if (textArrfLineHeight[action.payload.selectedTextIndex].heading) {
                    if (textArrfLineHeight[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrfLineHeight[action.payload.selectedTextIndex].heading.style = {
                            ...textArrfLineHeight[action.payload.selectedTextIndex].heading.style,
                            lineHeight: parseFloat(action.payload.value)
                        }
                    }
                }
                if (textArrfLineHeight[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrfLineHeight[action.payload.selectedTextIndex].text.style = {
                        ...textArrfLineHeight[action.payload.selectedTextIndex].text.style,
                        lineHeight: parseFloat(action.payload.value)
                    }
                }
            }
            else {
                if (textArrfLineHeight[action.payload.selectedTextIndex].heading) {
                    if (textArrfLineHeight[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrfLineHeight[action.payload.selectedTextIndex].heading.style = {
                            ...textArrfLineHeight[action.payload.selectedTextIndex].heading.style,
                            lineHeight: parseFloat(action.payload.value)
                        }
                    }
                }
                if (textArrfLineHeight[action.payload.selectedTextIndex].text) {
                    if (textArrfLineHeight[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrfLineHeight[action.payload.selectedTextIndex].text.style = {
                            ...textArrfLineHeight[action.payload.selectedTextIndex].text.style,
                            lineHeight: parseFloat(action.payload.value)
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrfLineHeight
                }
            }

        case 'REMOVE_FONT_BG_COLOR':
            let slidesArrRemoveBG = [...state.data.slides]
            let textArrRemoveBG = [...slidesArrRemoveBG[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrRemoveBG[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrRemoveBG[action.payload.selectedTextIndex].name.style = {
                        ...textArrRemoveBG[action.payload.selectedTextIndex].name.style,
                        bgColor: ''
                    }
                }
                if (textArrRemoveBG[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrRemoveBG[action.payload.selectedTextIndex].designation.style = {
                        ...textArrRemoveBG[action.payload.selectedTextIndex].designation.style,
                        bgColor: ''
                    }
                }
                if (textArrRemoveBG[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrRemoveBG[action.payload.selectedTextIndex].text.style = {
                        ...textArrRemoveBG[action.payload.selectedTextIndex].text.style,
                        bgColor: ''
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrRemoveBG[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrRemoveBG[action.payload.selectedTextIndex].value.style = {
                        ...textArrRemoveBG[action.payload.selectedTextIndex].value.style,
                        bgColor: ''
                    }
                }
                if (textArrRemoveBG[action.payload.selectedTextIndex].heading) {
                    if (textArrRemoveBG[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrRemoveBG[action.payload.selectedTextIndex].heading.style = {
                            ...textArrRemoveBG[action.payload.selectedTextIndex].heading.style,
                            bgColor: ''
                        }
                    }
                }
                if (textArrRemoveBG[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrRemoveBG[action.payload.selectedTextIndex].text.style = {
                        ...textArrRemoveBG[action.payload.selectedTextIndex].text.style,
                        bgColor: ''
                    }
                }
            }
            else {
                if (textArrRemoveBG[action.payload.selectedTextIndex].heading) {
                    if (textArrRemoveBG[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrRemoveBG[action.payload.selectedTextIndex].heading.style = {
                            ...textArrRemoveBG[action.payload.selectedTextIndex].heading.style,
                            bgColor: ''
                        }
                    }
                }
                if (textArrRemoveBG[action.payload.selectedTextIndex].text) {
                    if (textArrRemoveBG[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrRemoveBG[action.payload.selectedTextIndex].text.style = {
                            ...textArrRemoveBG[action.payload.selectedTextIndex].text.style,
                            bgColor: ''
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrRemoveBG
                }
            }

        case 'UPDATE_FONT_BOLD':
            let slidesArrBold = [...state.data.slides]
            let textArrBold = [...slidesArrBold[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrBold[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrBold[action.payload.selectedTextIndex].name.style = {
                        ...textArrBold[action.payload.selectedTextIndex].name.style,
                        bold: action.payload.status
                    }
                }
                if (textArrBold[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrBold[action.payload.selectedTextIndex].designation.style = {
                        ...textArrBold[action.payload.selectedTextIndex].designation.style,
                        bold: action.payload.status
                    }
                }
                if (textArrBold[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrBold[action.payload.selectedTextIndex].text.style = {
                        ...textArrBold[action.payload.selectedTextIndex].text.style,
                        bold: action.payload.status
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrBold[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrBold[action.payload.selectedTextIndex].value.style = {
                        ...textArrBold[action.payload.selectedTextIndex].value.style,
                        bold: action.payload.status
                    }
                }
                if (textArrBold[action.payload.selectedTextIndex].heading) {
                    if (textArrBold[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrBold[action.payload.selectedTextIndex].heading.style = {
                            ...textArrBold[action.payload.selectedTextIndex].heading.style,
                            bold: action.payload.status
                        }
                    }
                }
                if (textArrBold[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrBold[action.payload.selectedTextIndex].text.style = {
                        ...textArrBold[action.payload.selectedTextIndex].text.style,
                        bold: action.payload.status
                    }
                }
            }
            else {
                if (textArrBold[action.payload.selectedTextIndex].heading) {
                    if (textArrBold[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrBold[action.payload.selectedTextIndex].heading.style = {
                            ...textArrBold[action.payload.selectedTextIndex].heading.style,
                            bold: action.payload.status
                        }
                    }
                }
                if (textArrBold[action.payload.selectedTextIndex].text) {
                    if (textArrBold[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrBold[action.payload.selectedTextIndex].text.style = {
                            ...textArrBold[action.payload.selectedTextIndex].text.style,
                            bold: action.payload.status
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrBold
                }
            }


        case 'UPDATE_FONT_ITALIC':
            let slidesArrItalic = [...state.data.slides]
            let textArrItalic = [...slidesArrItalic[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrItalic[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrItalic[action.payload.selectedTextIndex].name.style = {
                        ...textArrItalic[action.payload.selectedTextIndex].name.style,
                        italic: action.payload.status
                    }
                }
                if (textArrItalic[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrItalic[action.payload.selectedTextIndex].designation.style = {
                        ...textArrItalic[action.payload.selectedTextIndex].designation.style,
                        italic: action.payload.status
                    }
                }
                if (textArrItalic[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrItalic[action.payload.selectedTextIndex].text.style = {
                        ...textArrItalic[action.payload.selectedTextIndex].text.style,
                        italic: action.payload.status
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrItalic[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrItalic[action.payload.selectedTextIndex].value.style = {
                        ...textArrItalic[action.payload.selectedTextIndex].value.style,
                        italic: action.payload.status
                    }
                }
                if (textArrItalic[action.payload.selectedTextIndex].heading) {
                    if (textArrItalic[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrItalic[action.payload.selectedTextIndex].heading.style = {
                            ...textArrItalic[action.payload.selectedTextIndex].heading.style,
                            italic: action.payload.status
                        }
                    }
                }
                if (textArrItalic[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrItalic[action.payload.selectedTextIndex].text.style = {
                        ...textArrItalic[action.payload.selectedTextIndex].text.style,
                        italic: action.payload.status
                    }
                }
            }
            else {
                if (textArrItalic[action.payload.selectedTextIndex].heading) {
                    if (textArrItalic[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrItalic[action.payload.selectedTextIndex].heading.style = {
                            ...textArrItalic[action.payload.selectedTextIndex].heading.style,
                            italic: action.payload.status
                        }
                    }
                }
                if (textArrItalic[action.payload.selectedTextIndex].text) {
                    if (textArrItalic[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrItalic[action.payload.selectedTextIndex].text.style = {
                            ...textArrItalic[action.payload.selectedTextIndex].text.style,
                            italic: action.payload.status
                        }
                    }

                }
            }


            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrItalic
                }
            }

        case 'UPDATE_FONT_UPPER':
            let slidesArrUpper = [...state.data.slides]
            let textArrUpper = [...slidesArrUpper[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrUpper[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrUpper[action.payload.selectedTextIndex].name.style = {
                        ...textArrUpper[action.payload.selectedTextIndex].name.style,
                        upperCase: action.payload.status,
                        lowerCase: action.payload.lowerFalse
                    }
                }
                if (textArrUpper[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrUpper[action.payload.selectedTextIndex].designation.style = {
                        ...textArrUpper[action.payload.selectedTextIndex].designation.style,
                        upperCase: action.payload.status,
                        lowerCase: action.payload.lowerFalse
                    }
                }
                if (textArrUpper[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrUpper[action.payload.selectedTextIndex].text.style = {
                        ...textArrUpper[action.payload.selectedTextIndex].text.style,
                        upperCase: action.payload.status,
                        lowerCase: action.payload.lowerFalse
                    }
                }
            } else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrUpper[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrUpper[action.payload.selectedTextIndex].value.style = {
                        ...textArrUpper[action.payload.selectedTextIndex].value.style,
                        upperCase: action.payload.status,
                        lowerCase: action.payload.lowerFalse
                    }
                }
                if (textArrUpper[action.payload.selectedTextIndex].heading) {
                    if (textArrUpper[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrUpper[action.payload.selectedTextIndex].heading.style = {
                            ...textArrUpper[action.payload.selectedTextIndex].heading.style,
                            upperCase: action.payload.status,
                            lowerCase: action.payload.lowerFalse
                        }
                    }
                }
                if (textArrUpper[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrUpper[action.payload.selectedTextIndex].text.style = {
                        ...textArrUpper[action.payload.selectedTextIndex].text.style,
                        upperCase: action.payload.status,
                        lowerCase: action.payload.lowerFalse
                    }
                }
            }
            else {
                if (textArrUpper[action.payload.selectedTextIndex].heading) {
                    if (textArrUpper[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrUpper[action.payload.selectedTextIndex].heading.style = {
                            ...textArrUpper[action.payload.selectedTextIndex].heading.style,
                            upperCase: action.payload.status,
                            lowerCase: action.payload.lowerFalse
                        }
                    }
                }
                if (textArrUpper[action.payload.selectedTextIndex].text) {
                    if (textArrUpper[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrUpper[action.payload.selectedTextIndex].text.style = {
                            ...textArrUpper[action.payload.selectedTextIndex].text.style,
                            upperCase: action.payload.status,
                            lowerCase: action.payload.lowerFalse
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrUpper
                }
            }

        case 'UPDATE_FONT_LOWER':
            let slidesArrLower = [...state.data.slides]
            let textArrLower = [...slidesArrLower[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrLower[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrLower[action.payload.selectedTextIndex].name.style = {
                        ...textArrLower[action.payload.selectedTextIndex].name.style,
                        lowerCase: action.payload.status,
                        upperCase: action.payload.upperFalse,
                    }
                }
                if (textArrLower[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrLower[action.payload.selectedTextIndex].designation.style = {
                        ...textArrLower[action.payload.selectedTextIndex].designation.style,
                        lowerCase: action.payload.status,
                        upperCase: action.payload.upperFalse,
                    }
                }
                if (textArrLower[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrLower[action.payload.selectedTextIndex].text.style = {
                        ...textArrLower[action.payload.selectedTextIndex].text.style,
                        lowerCase: action.payload.status,
                        upperCase: action.payload.upperFalse,
                    }
                }
            } else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrLower[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrLower[action.payload.selectedTextIndex].value.style = {
                        ...textArrLower[action.payload.selectedTextIndex].value.style,
                        lowerCase: action.payload.status,
                        upperCase: action.payload.upperFalse,
                    }
                }
                if (textArrLower[action.payload.selectedTextIndex].heading) {
                    if (textArrLower[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrLower[action.payload.selectedTextIndex].heading.style = {
                            ...textArrLower[action.payload.selectedTextIndex].heading.style,
                            lowerCase: action.payload.status,
                            upperCase: action.payload.upperFalse,
                        }
                    }
                }
                if (textArrLower[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrLower[action.payload.selectedTextIndex].text.style = {
                        ...textArrLower[action.payload.selectedTextIndex].text.style,
                        lowerCase: action.payload.status,
                        upperCase: action.payload.upperFalse,
                    }
                }
            }
            else {
                if (textArrLower[action.payload.selectedTextIndex].heading) {
                    if (textArrLower[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrLower[action.payload.selectedTextIndex].heading.style = {
                            ...textArrLower[action.payload.selectedTextIndex].heading.style,
                            lowerCase: action.payload.status,
                            upperCase: action.payload.upperFalse,
                        }
                    }
                }
                if (textArrLower[action.payload.selectedTextIndex].text) {
                    if (textArrLower[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrLower[action.payload.selectedTextIndex].text.style = {
                            ...textArrLower[action.payload.selectedTextIndex].text.style,
                            lowerCase: action.payload.status,
                            upperCase: action.payload.upperFalse,
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrLower
                }
            }

        case 'UPDATE_FONT_UNDERLINE':
            let slidesArrUnderline = [...state.data.slides]
            let textArrUnderline = [...slidesArrUnderline[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrUnderline[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrUnderline[action.payload.selectedTextIndex].name.style = {
                        ...textArrUnderline[action.payload.selectedTextIndex].name.style,
                        underline: action.payload.status,
                        strike: action.payload.strikeFalse
                    }
                }
                if (textArrUnderline[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrUnderline[action.payload.selectedTextIndex].designation.style = {
                        ...textArrUnderline[action.payload.selectedTextIndex].designation.style,
                        underline: action.payload.status,
                        strike: action.payload.strikeFalse
                    }
                }
                if (textArrUnderline[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrUnderline[action.payload.selectedTextIndex].text.style = {
                        ...textArrUnderline[action.payload.selectedTextIndex].text.style,
                        underline: action.payload.status,
                        strike: action.payload.strikeFalse
                    }
                }
            } else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrUnderline[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrUnderline[action.payload.selectedTextIndex].value.style = {
                        ...textArrUnderline[action.payload.selectedTextIndex].value.style,
                        underline: action.payload.status,
                        strike: action.payload.strikeFalse
                    }
                }
                if (textArrUnderline[action.payload.selectedTextIndex].heading) {
                    if (textArrUnderline[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrUnderline[action.payload.selectedTextIndex].heading.style = {
                            ...textArrUnderline[action.payload.selectedTextIndex].heading.style,
                            underline: action.payload.status,
                            strike: action.payload.strikeFalse
                        }
                    }
                }
                if (textArrUnderline[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrUnderline[action.payload.selectedTextIndex].text.style = {
                        ...textArrUnderline[action.payload.selectedTextIndex].text.style,
                        underline: action.payload.status,
                        strike: action.payload.strikeFalse
                    }
                }
            }
            else {
                if (textArrUnderline[action.payload.selectedTextIndex].heading) {
                    if (textArrUnderline[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrUnderline[action.payload.selectedTextIndex].heading.style = {
                            ...textArrUnderline[action.payload.selectedTextIndex].heading.style,
                            underline: action.payload.status,
                            strike: action.payload.strikeFalse
                        }
                    }
                }
                if (textArrUnderline[action.payload.selectedTextIndex].text) {
                    if (textArrUnderline[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrUnderline[action.payload.selectedTextIndex].text.style = {
                            ...textArrUnderline[action.payload.selectedTextIndex].text.style,
                            underline: action.payload.status,
                            strike: action.payload.strikeFalse
                        }
                    }

                }
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrUnderline
                }
            }

        case 'UPDATE_FONT_STRIKE':
            let slidesArrStrike = [...state.data.slides]
            let textArrStrike = [...slidesArrStrike[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrStrike[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrStrike[action.payload.selectedTextIndex].name.style = {
                        ...textArrStrike[action.payload.selectedTextIndex].name.style,
                        strike: action.payload.status,
                        underline: action.payload.underlineFalse
                    }
                }
                if (textArrStrike[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrStrike[action.payload.selectedTextIndex].designation.style = {
                        ...textArrStrike[action.payload.selectedTextIndex].designation.style,
                        strike: action.payload.status,
                        underline: action.payload.underlineFalse
                    }
                }
                if (textArrStrike[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrStrike[action.payload.selectedTextIndex].text.style = {
                        ...textArrStrike[action.payload.selectedTextIndex].text.style,
                        strike: action.payload.status,
                        underline: action.payload.underlineFalse
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrStrike[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrStrike[action.payload.selectedTextIndex].value.style = {
                        ...textArrStrike[action.payload.selectedTextIndex].value.style,
                        strike: action.payload.status,
                        underline: action.payload.underlineFalse
                    }
                }
                if (textArrStrike[action.payload.selectedTextIndex].heading) {
                    if (textArrStrike[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrStrike[action.payload.selectedTextIndex].heading.style = {
                            ...textArrStrike[action.payload.selectedTextIndex].heading.style,
                            strike: action.payload.status,
                            underline: action.payload.underlineFalse
                        }
                    }
                }
                if (textArrStrike[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrStrike[action.payload.selectedTextIndex].text.style = {
                        ...textArrStrike[action.payload.selectedTextIndex].text.style,
                        strike: action.payload.status,
                        underline: action.payload.underlineFalse
                    }
                }
            }
            else {
                if (textArrStrike[action.payload.selectedTextIndex].heading) {
                    if (textArrStrike[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrStrike[action.payload.selectedTextIndex].heading.style = {
                            ...textArrStrike[action.payload.selectedTextIndex].heading.style,
                            strike: action.payload.status,
                            underline: action.payload.underlineFalse
                        }
                    }
                }
                if (textArrStrike[action.payload.selectedTextIndex].text) {
                    if (textArrStrike[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrStrike[action.payload.selectedTextIndex].text.style = {
                            ...textArrStrike[action.payload.selectedTextIndex].text.style,
                            strike: action.payload.status,
                            underline: action.payload.underlineFalse
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrStrike
                }
            }

        case 'UPDATE_FONT_ALIGN':
            let slidesArrAlign = [...state.data.slides]
            let textArrAlign = [...slidesArrAlign[action.payload.selectedSlideIndex].text]

            if (action.payload.type === "testimonials") {
                if (textArrAlign[action.payload.selectedTextIndex].name.is_selected === true) {
                    textArrAlign[action.payload.selectedTextIndex].name.style = {
                        ...textArrAlign[action.payload.selectedTextIndex].name.style,
                        align: action.payload.align
                    }
                }
                if (textArrAlign[action.payload.selectedTextIndex].designation.is_selected === true) {
                    textArrAlign[action.payload.selectedTextIndex].designation.style = {
                        ...textArrAlign[action.payload.selectedTextIndex].designation.style,
                        align: action.payload.align
                    }
                }
                if (textArrAlign[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrAlign[action.payload.selectedTextIndex].text.style = {
                        ...textArrAlign[action.payload.selectedTextIndex].text.style,
                        align: action.payload.align
                    }
                }
            }
            else if (action.payload.type === "bonuses" || action.payload.type === "bonusesDedicated") {
                if (textArrAlign[action.payload.selectedTextIndex].value.is_selected === true) {
                    textArrAlign[action.payload.selectedTextIndex].value.style = {
                        ...textArrAlign[action.payload.selectedTextIndex].value.style,
                        align: action.payload.align
                    }
                }
                if (textArrAlign[action.payload.selectedTextIndex].heading) {
                    if (textArrAlign[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrAlign[action.payload.selectedTextIndex].heading.style = {
                            ...textArrAlign[action.payload.selectedTextIndex].heading.style,
                            align: action.payload.align
                        }
                    }
                }
                if (textArrAlign[action.payload.selectedTextIndex].text.is_selected === true) {
                    textArrAlign[action.payload.selectedTextIndex].text.style = {
                        ...textArrAlign[action.payload.selectedTextIndex].text.style,
                        align: action.payload.align
                    }
                }
            }
            else {
                if (textArrAlign[action.payload.selectedTextIndex].heading) {
                    if (textArrAlign[action.payload.selectedTextIndex].heading.is_selected === true) {
                        textArrAlign[action.payload.selectedTextIndex].heading.style = {
                            ...textArrAlign[action.payload.selectedTextIndex].heading.style,
                            align: action.payload.align
                        }
                    }
                }
                if (textArrAlign[action.payload.selectedTextIndex].text) {
                    if (textArrAlign[action.payload.selectedTextIndex].text.is_selected === true) {
                        textArrAlign[action.payload.selectedTextIndex].text.style = {
                            ...textArrAlign[action.payload.selectedTextIndex].text.style,
                            align: action.payload.align
                        }
                    }

                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrAlign
                }
            }

        case 'UPDATE_SLIDE_TEXT_IMAGE':
            let slidesArrTextImg = [...state.data.slides]
            slidesArrTextImg[action.payload.selectedSlideIndex].text[action.payload.selectedTextIndex].image = action.payload.src
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrTextImg
                }
            }

        case 'UPDATE_SLIDE_PRODUCT_IMAGE':
            let slidesArrProductImg = [...state.data.slides]
            slidesArrProductImg[action.payload.selectedSlideIndex].productImage = action.payload.src
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrProductImg
                }
            }

        case 'UPDATE_SLIDE_IMAGE':

            let slidesArrImage = [...state.data.slides]
            slidesArrImage[action.payload.selectedSlideIndex].backgroundData = {
                ...slidesArrImage[action.payload.selectedSlideIndex].backgroundData,
                fileUrl: action.payload.image.fileUrl,
                poster: action.payload.image.poster,
                type: action.payload.image.type
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrImage
                }
            }

        case 'UPDATE_SLIDE_VIDEO':
            let slidesArrVideo = [...state.data.slides]
            slidesArrVideo[action.payload.selectedSlideIndex].backgroundData = {
                ...slidesArrVideo[action.payload.selectedSlideIndex].backgroundData,
                fileUrl: action.payload.video.fileUrl,
                poster: action.payload.video.poster,
                type: action.payload.video.type
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrVideo
                }
            }

        case 'UPDATE_TTS_TEXT':
            let slidesArrTtsText = [...state.data.slides]
            slidesArrTtsText[action.payload.selectedSlideIndex].audio = {
                ...slidesArrTtsText[action.payload.selectedSlideIndex].audio,
                tts: {
                    ...slidesArrTtsText[action.payload.selectedSlideIndex].audio.tts,
                    text: action.payload.text
                }
            }
            return {
                ...state
            }


        case 'UPDATE_STATUS_L&W':
            if (action.payload.type === 'logo') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        logo: {
                            ...state.data.logo,
                            enable: action.payload.enable
                        }
                    }
                }
            }
            else if (action.payload.type === 'waterMark') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        waterMark: {
                            ...state.data.waterMark,
                            enable: action.payload.enable
                        }
                    }
                }
            }
            return {
                ...state
            }

        case 'ADD_LOGO':
            return {
                ...state,
                data: {
                    ...state.data,
                    logo: {
                        ...state.data.logo,
                        src: action.payload
                    }
                }
            }

        case 'ADD_WATERMARK':
            return {
                ...state,
                data: {
                    ...state.data,
                    waterMark: {
                        ...state.data.waterMark,
                        src: action.payload
                    }
                }
            }

        case 'REMOVE_LOGO_LAYER':
            return {
                ...state,
                data: {
                    ...state.data,
                    logo: {
                        ...state.data.logo,
                        src: '',
                        postion: 'top-left'
                    }
                }
            }

        case 'REMOVE_WATERMARK_LAYER':
            return {
                ...state,
                data: {
                    ...state.data,
                    waterMark: {
                        ...state.data.waterMark,
                        src: '',
                        postion: 'top-left'
                    }
                }
            }

        case 'REMOVE_DIMMER_LAYER':
            state.data.dimmer = action.payload
            return {
                ...state
            }

        case 'UPDATE_LOGO_POSITION':
            return {
                ...state,
                data: {
                    ...state.data,
                    logo: {
                        ...state.data.logo,
                        postion: action.payload
                    }
                }
            }

        case 'UPDATE_WATERMARK_POSITION':
            return {
                ...state,
                data: {
                    ...state.data,
                    waterMark: {
                        ...state.data.waterMark,
                        postion: action.payload
                    }
                }
            }

        case 'CHANGE_BG_VOLUME':
            return {
                ...state,
                data: {
                    ...state.data,
                    bgAudio: {
                        ...state.data.bgAudio,
                        volume: parseInt(action.payload)
                    }
                }
            }

        case 'CHANGE_VOICE_VOLUME':
            let slidesArrVoice = [...state.data.slides]
            slidesArrVoice[action.payload.selectedSlideIndex].audio = {
                ...slidesArrVoice[action.payload.selectedSlideIndex].audio,
                volume: parseInt(action.payload.value)
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrVoice
                }
            }

        case 'ADD_TTS_DATA':
            let slidesArrTts = [...state.data.slides]
            slidesArrTts[action.payload.selectedSlideIndex].audio = {
                ...slidesArrTts[action.payload.selectedSlideIndex].audio,
                enable: action.payload.enable,
                url: action.payload.tts.url,
                tts: action.payload.tts.tts,
                duration: action.payload.tts.duration
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrTts
                }
            }

        case 'ADD_BGAUDIO_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    bgAudio: {
                        ...state.data.bgAudio,
                        src: action.payload.url,
                        enable: true
                    }
                }
            }

        case 'REMOVE_TTSAUDIO_SLIDES':

            let slidesArrTtsRemove = [...state.data.slides]
            slidesArrTtsRemove[action.payload.selectedSlideIndex].audio = {
                ...slidesArrTtsRemove[action.payload.selectedSlideIndex].audio,
                enable: false,
                url: '',
                tts: action.payload.tts
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slidesArrTtsRemove
                }
            }

        case 'REMOVE_BGAUDIO_SLIDES':
            return {
                ...state,
                data: {
                    ...state.data,
                    bgAudio: {
                        ...state.data.bgAudio,
                        src: '',
                        enable: false
                    }
                }
            }

        case 'SET_INTRO_STATUS':
            return {
                ...state,
                data: {
                    ...state.data,
                    intro: {
                        ...state.data.intro,
                        enable: action.payload
                    }
                }
            }
        case 'SET_OUTRO_STATUS':

            return {
                ...state,
                data: {
                    ...state.data,
                    outro: {
                        ...state.data.outro,
                        enable: action.payload
                    }
                }
            }

        case 'REMOVE_INTRO':
            return {
                ...state,
                data: {
                    ...state.data,
                    intro: {
                        ...state.data.intro,
                        src: ''
                    }
                }
            }

        case 'REMOVE_OUTRO':
            return {
                ...state,
                data: {
                    ...state.data,
                    outro: {
                        ...state.data.outro,
                        src: ''
                    }
                }
            }

        case 'UPDATE_INTRO': {
            return {
                ...state,
                data: {
                    ...state.data,
                    intro: {
                        ...state.data.intro,
                        src: action.payload
                    }
                }
            }
        }
        case 'UPDATE_OUTRO': {
            return {
                ...state,
                data: {
                    ...state.data,
                    outro: {
                        ...state.data.outro,
                        src: action.payload
                    }
                }
            }
        }
        case 'APPLY_TO_ALL_SLIDE':
            let slides = [...state.data.slides]
            const selectedStyle = action.payload
            slides.forEach((curElem) => {
                curElem.text.forEach((curStyle) => {
                    if (curElem.type === "testimonials") {
                        curStyle.name.style = selectedStyle
                        curStyle.text.style = selectedStyle
                        curStyle.designation.style = selectedStyle
                    }
                    else if (curElem.type === "featureDedicated") {
                        curStyle.heading.style = selectedStyle
                        curStyle.text.style = selectedStyle
                    }
                    else if (curElem.type === "bonuses" || curElem.type === "bonusesDedicated") {
                        curStyle.value.style = selectedStyle
                        curStyle.text.style = selectedStyle
                        if (curStyle.heading) {
                            curStyle.heading.style = selectedStyle
                        }
                    }
                    else {
                        curStyle.text.style = selectedStyle
                    }
                })
            })

            return {
                ...state,
                data: {
                    ...state.data,
                    slides: slides
                }
            }

        case 'SET_AFFILIATE_TEXT':
            return {
                ...state,
                data: {
                    ...state.data,
                    affiliate_link: {
                        ...state.data.affiliate_link,
                        text: action.payload
                    }
                }
            }

        case 'SET_AFFILIATE_STATUS':
            return {
                ...state,
                data: {
                    ...state.data,
                    affiliate_link: {
                        ...state.data.affiliate_link,
                        enable: action.payload
                    }
                }
            }

        case 'SET_AFFILIATE_POS':
            return {
                ...state,
                data: {
                    ...state.data,
                    affiliate_link: {
                        ...state.data.affiliate_link,
                        postion: action.payload
                    }
                }
            }

        case 'SET_AFFILIATE_COLOR':
            return {
                ...state,
                data: {
                    ...state.data,
                    affiliate_link: {
                        ...state.data.affiliate_link,
                        color: action.payload
                    }
                }
            }

        case 'SET_AFFILIATE_BGCOLOR':
            return {
                ...state,
                data: {
                    ...state.data,
                    affiliate_link: {
                        ...state.data.affiliate_link,
                        bgColor: action.payload
                    }
                }
            }

        case 'SET_AFFILIATE_FONTSIZE':
            return {
                ...state,
                data: {
                    ...state.data,
                    affiliate_link: {
                        ...state.data.affiliate_link,
                        fontSize: action.payload
                    }
                }
            }

        case 'SET_AFFILIATE_FONTFAMILY':
            return {
                ...state,
                data: {
                    ...state.data,
                    affiliate_link: {
                        ...state.data.affiliate_link,
                        fontFamily: action.payload
                    }
                }
            }


        default:
            return state

    }
}
