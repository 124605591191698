const initialState = {
    facebook : [],
    youtube: [],
    scriptreel: [],
    livereel: [],
    trimreel: [],
    syvid: [],
    sonority: [],
    videoreel:[]
}

export default function (state = initialState, action){
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            return{
                ...state,
                facebook: action.payload.data.facebook,
                youtube: action.payload.data.youtube,
                scriptreel: action.payload.data.scriptreel,
                livereel: action.payload.data.livereel,
                trimreel: action.payload.data.trimreel,
                syvid: action.payload.data.syvid,
                sonority: action.payload.data.sonority,
                videoreel: action.payload.data.videoreel
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}